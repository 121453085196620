import axios, { AxiosResponse } from 'axios';
import constants from '../utils/constantes';

const token = localStorage.getItem('token');
axios.defaults.baseURL = constants.BASE_URL;

const responseBody = (response: AxiosResponse) => response.data;

const request = {
  get: (url: string) => axios.get(url,{headers:{Authorization: ''}}).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body,{headers:{Authorization: ''}}).then(responseBody),  
};

const RdStation = {
  usuarioConversion: (body: any) =>
    request.post('api/usuario-conversion-rd/', body)
    .catch(error => {
      throw error.response;
    }),
};

export default RdStation;
