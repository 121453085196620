import React from 'react';
import {
  Button, 
} from 'semantic-ui-react';
import './index.scss';

type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};

type MyState = {  
  timer: any,
  time: any,
  seconds: any,
  startTimer: any, 
  countDown: any, 
};

export default class CountdownTimer extends React.Component<any, MyState>{
  constructor(props:any) {
    super(props);
    let startSeconds = 360;
    if(props.seconds===undefined || props.seconds=== null)
    {
      const CountdownTimerSecondsJSON = localStorage.getItem('CountdownTimerSeconds') as string;
      let CountdownTimerSeconds = JSON.parse(CountdownTimerSecondsJSON);
      startSeconds = CountdownTimerSeconds.seconds;
    }
    this.state = {
      time: {}, 
      seconds:startSeconds, 
      timer: 0, 
      startTimer: 0, 
      countDown: 0, 
    };
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    const { seconds } = this.state;
    let timeLeftVar = this.secondsToTime(seconds);
    this.setState({ time: timeLeftVar });
    this.startTimer();
  }

  secondsToTime = (secs:any) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      'h': hours,
      'm': minutes,
      's': seconds
    };
    return obj;
  }

  startTimer() {
    if (this.state.timer === 0 && this.state.seconds > 0) {
      this.setState({timer: setInterval(this.countDown, 1000)});
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    const { seconds } = this.state;
    let newseconds = seconds - 1;
    this.setState({
      time: this.secondsToTime(newseconds),
      seconds: newseconds,
    });
    localStorage.setItem('CountdownTimerSeconds', JSON.stringify({seconds: newseconds}));
    
    // Check if we're at zero.
    if (newseconds === 0) { 
      clearInterval(this.state.timer);
      let resetSeconds = 360;
      this.setState({
        time: {}, 
        seconds:resetSeconds, 
        timer: 0, 
        startTimer: 0, 
        countDown: 0, 
      });
      localStorage.setItem('CountdownTimerSeconds', JSON.stringify({seconds: resetSeconds}));
      /*
      this.componentDidMount();
      */
      
      this.startTimer();

    }
  }

  render() {
    return(
      <div className='CountdownTimerContainer'>

        <div className='CountdownTimerText'>
          Sua oferta expira em: 
        </div>
        <div className='CountdownTimerTimer'>
          <span>{this.state.time.m} minuto(s)</span> e <span>{this.state.time.s} segundo(s)</span>!
        </div>

      </div>
    );
  }
}