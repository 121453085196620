import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Loader,
  Segment,
  Dimmer,
  Image,
  Header,
  Button, 
  Form, 
  Icon,
  Select,
} from 'semantic-ui-react';
import InputMask from 'react-input-mask';

import Rotas from '../../../utils/rotas';
import '../login.scss';
import './registrar.scss';
import Logo from '../../../assets/imgs/logo.png';
import AuthService from '../../../services/authService';
import RdStation from '../../../services/rdStation';
import IAuth from '../../../models/auth';

import FormField from '../../../components/FormField';
import Loading from '../../../components/Loading';
import CountdownTimer from '../../../components/CountdownTimer';


type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  redirectTo:any, 
  loading:boolean, 
  hasError:boolean, 
  errorMessage: string, 
  show_fields:boolean, 
  categoria_cliente: string, 
  categorias: any, 
};

export default class RegistrarCategoria extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      redirectTo: false, 
      loading:true, 
      hasError: false, 
      errorMessage: '', 
      show_fields: false, 
      categoria_cliente: 'advogado',
      categorias: [
        {value:'academia', text:'Academia'},
        {value:'advogado', text:'Advogado'},
        {value:'assistencia tecnica para celulares', text:'Assistência técnica para celulares'},
        {value:'clinica de fisioterapia', text:'Clínica de Fisioterapia'},
        {value:'clinica medica', text:'Clínica Médica'},
        {value:'clinica odontologica', text:'Clínica Odontológica'},
        {value:'coachs', text:'Coachs'},
        {value:'contabilidade', text:'Contabilidade'},
        {value:'corretor imoveis', text:'Corretor de Imóveis'},
        {value:'despachante', text:'Despachante'},
        {value:'diarista', text:'Diarista'},
        {value:'empresa', text:'Empresa'},
        {value:'fisioterapia', text:'Fisioterapia'},
        {value:'food', text:'Food'},
        {value:'nutricionista', text:'Nutricionista'},
        {value:'odontologia', text:'Odontologia'},
        {value:'personal trainer', text:'Personal Trainer'},
        {value:'autonomo', text:'Profissional Autônomo'},
        {value:'psicologia', text:'Psicologia'},
        {value:'saude', text:'Saúde'},
        {value:'outros', text:'Outros'},
      ]
    };
  }

  componentDidMount = async () => {
    let isLogged:any = localStorage.getItem('isLogged');
    if(isLogged!==null)
    {
      isLogged = JSON.parse(isLogged);
      if(isLogged)
      {
        this.setState({hasError:false,redirectTo:Rotas.DASHBOARD});
      }
    }
    this.setState({loading:false});
  }

  onChangeCategoriaCliente = (event:any, item:any) =>{
    this.setState({categoria_cliente:item.value});
  }

  handleNextStep = async () => {
    let categoriaInfo = {
      categoria: this.state.categoria_cliente
    }

    let registrarSeInfo = null;
    let registrarSeInfoJSONParse = null;
    registrarSeInfo = localStorage.getItem('registrarSeInfo') as string;
    registrarSeInfoJSONParse = JSON.parse(registrarSeInfo);
    
    let convertionData = null;
    let url_origem = new URLSearchParams(this.props.location.search).get('origem');
    console.warn('url_origem: ', url_origem);
    if(url_origem!==null)
    {
      convertionData = {'nome': registrarSeInfoJSONParse.nome, 'email': registrarSeInfoJSONParse.email, 'telefone': registrarSeInfoJSONParse.telefone, 'conversion_name': 'CADASTRO_WEB_INICIO', 'origem': url_origem, 'categoria': this.state.categoria_cliente};
    }
    else
    {
      convertionData = {'nome': registrarSeInfoJSONParse.nome, 'email': registrarSeInfoJSONParse.email, 'telefone': registrarSeInfoJSONParse.telefone, 'conversion_name': 'CADASTRO_WEB_INICIO', 'categoria': this.state.categoria_cliente};
    }

    const usuarioConversion = await RdStation.usuarioConversion(convertionData);

    localStorage.setItem('registrarCategoriaInfo', JSON.stringify(categoriaInfo));
    this.setState({loading: false, redirectTo:Rotas.REGISTRAR_PLANO});    
  }

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <>
        <Grid className='login registrar-categoria login-top-desc' verticalAlign='middle'>
          <Grid.Column tablet={12} computer={8}>
            <Segment padded>
              <Grid columns={1} className='login__title'>
                <Grid.Column>
                  <Header as='h1'>
                    Registrar <span>Área de Atuação</span>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <CountdownTimer/>
                </Grid.Column>
              </Grid>
              <Grid columns={1}>
                <Grid.Column>
                  {
                    this.state.hasError ? 
                    <div className="ErrorLogin">{this.state.errorMessage}</div>
                    : 
                    null
                  }
                  <Form>
                    <Grid columns={1}>
                      <Grid.Column>
                        <Form.Field className='form-field'>
                          <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                          <label htmlFor='categoria_cliente'>Categoria</label>
                          <Select 
                            placeholder='Selecione sua área de atuação' 
                            options={this.state.categorias} 
                            value={this.state.categoria_cliente}
                            onChange={this.onChangeCategoriaCliente}
                            className='SelectAreaAtuacao'
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid>
                  </Form>
                  <Grid columns={1}>
                    <Grid.Column>
                      <Button
                        primary
                        size='large'
                        fluid
                        onClick={() => this.handleNextStep()}
                      >
                        Continuar
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>
        
      </>
    );
  }
};

