import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Loader,
  Segment,
  Dimmer,
  Image,
  Header,
  Button,
  Form,
} from 'semantic-ui-react';

import ReCAPTCHA from 'react-google-recaptcha';
import Rotas from '../../utils/rotas';
import './login.scss';
import Logo from '../../assets/imgs/logo.png';
import AuthService from '../../services/authService';
import IAuth from '../../models/auth';

import FormField from '../../components/FormField';
import Loading from '../../components/Loading';

type MyProps = {
  history: any,
  location: any,
  match: any,
  staticContext: any,
};
type MyState = {
  redirectTo: any,
  loading: boolean,
  hasError: boolean,
  isVerified: boolean,
  errorMessageLogin: string,
  email: string,
  senha: string,
  handleOnChange: boolean,
};

export default class Login extends React.Component<MyProps, MyState>{
  constructor(props: any) {
    super(props);
    this.state = { redirectTo: false, loading: true,handleOnChange:false, isVerified: false, hasError: false, errorMessageLogin: '', email: '', senha: '' };
  }

  handleOnChange = (value: any) => {
    console.log('Captcha value:', value);
    this.setState({isVerified: true});
  }

  componentDidMount = async () => {
    let isLogged: any = localStorage.getItem('isLogged');
    if (isLogged !== null) {
      isLogged = JSON.parse(isLogged);
      if (isLogged) {
        this.setState({ hasError: false, redirectTo: Rotas.DASHBOARD });
      }
    }
    this.setState({ loading: false });
  }

  handleLogin = async (email: string, password: string) => {
    if (email !== '' && password !== '') {
      const auth: IAuth = { email, password };
      let authResponse = null;
      try {
        authResponse = await AuthService.attemp(auth);
      } catch (error) {
        const errors_exception = error.data;
        /*
        let key_name = Object.keys(errors_exception.errors)[0];
        */
        this.setState({ hasError: true });
        return;
      }
      try {
        localStorage.setItem('token', authResponse.token);
        localStorage.setItem('isLogged', JSON.stringify(true));
        localStorage.setItem('loginError', '');
      } catch (error) {
        localStorage.setItem('isLogged', JSON.stringify(false));
        localStorage.setItem('loginError', '');
        localStorage.removeItem('token');
      }
      let isLogged: any = localStorage.getItem('isLogged');
      if (isLogged !== null) {
        isLogged = JSON.parse(isLogged);
      }
      else {
        isLogged = false;
      }
      if (isLogged) {
        this.setState({ hasError: false, redirectTo: Rotas.DASHBOARD });
      }
      else {
        this.setState({ hasError: true });
      }
    }
  };

  
  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    
    return (
      <>
        <Grid className='login login-bg' verticalAlign='middle'>
          <Grid.Column tablet={12} computer={8}>
            <Segment padded>
              <Grid centered columns={1} padded='vertically'>
                <Grid.Column>
                  <Image src={Logo} centered />
                </Grid.Column>
              </Grid>
              <Grid columns={1} className='login__title'>
                <Grid.Column>
                  <Header as='h1'>
                    Faça login na <span>sua conta</span>
                  </Header>
                </Grid.Column>
              </Grid>
              <Grid columns={1}>
                <Grid.Column>
                  {
                    this.state.hasError ?
                      <div className="ErrorLogin">Usuário e/ou senha inválidos!</div>
                      :
                      null
                  }
                  <Form>
                    <Grid columns={1}>
                      <Grid.Column>
                        <FormField
                          name='Login'
                          type='email'
                          id='email'
                          placeholder='Digite o endereço de e-mail'
                          onChange={(val: string, prevVal: string) => this.setState({ email: val })}
                        />
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1}>
                      <Grid.Column>
                        <FormField
                          name='Senha'
                          type='password'
                          id='senha'
                          placeholder='Digite sua senha'
                          onChange={(val: string, prevVal: string) => this.setState({ senha: val })}
                        />
                      </Grid.Column>
                    </Grid>
                  </Form>
                  <Grid columns={1}>
                    <Grid.Column>
                      <div className='containerRecaptcha'>
                        <div className='positionRecaptcha'>
                          <p className='descRecap'> Para logar verifique se voce é um humano: </p>
                          <div className='containerIframeRecaptcha'>
                            <ReCAPTCHA className='recaptcha' sitekey="6LccvekfAAAAAK43rNtB5QtHEKBs2RdM8jSNOhty" onChange={this.handleOnChange}/>
                          </div>
                        </div>
                      </div>
                      <Button
                        primary
                        size='large'
                        fluid
                        disabled = {!this.state.isVerified}
                        onClick={() => this.handleLogin(this.state.email, this.state.senha)}
                      >
                        Entrar
                      </Button>
      
                    </Grid.Column>
                    <Grid columns={1} className='actions'>
                      <Grid.Column>
                        <p>
                          <a href='https://mobile.advogar.site/password_reset' target='_blank' rel="noreferrer">
                            Esqueceu sua senha?
                          </a>
                        </p>
                        <p>
                          <a href={Rotas.REGISTRAR_SE}>
                            Não tem uma conta no Adweb? <span>Registre-se</span>
                          </a>
                        </p>
                      </Grid.Column>
                    </Grid>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>
        <a className='whatsapp-widget'
          rel='noopener noreferrer'
          target='_blank'
          href='https://api.whatsapp.com/send?phone=553833219333&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20adicionais%20'
          title='Show Chat'>
          <svg width="20" viewBox="0 0 24 24"><defs /><path fill="#eceff1" d="M20.5 3.4A12.1 12.1 0 0012 0 12 12 0 001.7 17.8L0 24l6.3-1.7c2.8 1.5 5 1.4 5.8 1.5a12 12 0 008.4-20.3z" /><path fill="#4caf50" d="M12 21.8c-3.1 0-5.2-1.6-5.4-1.6l-3.7 1 1-3.7-.3-.4A9.9 9.9 0 012.1 12a10 10 0 0117-7 9.9 9.9 0 01-7 16.9z" /><path fill="#fafafa" d="M17.5 14.3c-.3 0-1.8-.8-2-.9-.7-.2-.5 0-1.7 1.3-.1.2-.3.2-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.3-.6.4-.6 1-1.7l-.1-.5-1-2.2c-.2-.6-.4-.5-.6-.5-.6 0-1 0-1.4.3-1.6 1.8-1.2 3.6.2 5.6 2.7 3.5 4.2 4.2 6.8 5 .7.3 1.4.3 1.9.2.6 0 1.7-.7 2-1.4.3-.7.3-1.3.2-1.4-.1-.2-.3-.3-.6-.4z" /></svg> Entrar em contato
        </a>
      </>
    );
  }
};

