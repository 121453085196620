import axios, { AxiosResponse } from 'axios';
import constants from '../utils/constantes';

const token = localStorage.getItem('token');
const api_suffix = '';

axios.defaults.baseURL = constants.BASE_URL;
/*
axios.defaults.headers.common.Authorization = `Token ${token}`;
*/

const responseBody = (response: AxiosResponse) => response.data;

const request = {
  get: (url: string) => axios.get(url,{headers:{Authorization: `Token ${token}`}}).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body,{headers:{Authorization: ''}}).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body,{headers:{Authorization: `Token ${token}`}}).then(responseBody),
};

const UtilService = {
  consultaPlanoCupom: (_body={}) =>
    request.post('api/consultar-plano-cupom/',_body).catch(error => {
      throw error.response;
    }),
};

export default UtilService;
