import React from 'react';
import { Form } from 'semantic-ui-react';
import './index.scss';

interface IFormField {
  name: string;
  type: string;
  id: string;
  placeholder: string;
  onChange: Function;
  value?: string | null;
}

const FormField: React.FC<IFormField> = ({
  name,
  type,
  id,
  placeholder,
  onChange,
  value,
}) => {
  return (
    <Form.Field className='form-field'>
      <label htmlFor={id}>{name}</label>
      <input
        type={type}
        id={id}
        value={value === null ? '' : value}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
      />
    </Form.Field>
  );
};

export default FormField;
