import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Redirect, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Loader,
  Segment,
  Dimmer,
  Image,
  Header,
  Button, 
  Form, 
  Icon,
  Modal,
  Checkbox,
} from 'semantic-ui-react';
import InputMask from 'react-input-mask';

import Rotas from '../../../utils/rotas';
import '../login.scss';
import './registrar.scss';
import Logo from '../../../assets/imgs/logo.png';
import SeloPagarme from '../../../assets/imgs/cadastro/Selo-Pagar-me-Aplicacao-horizontal-Fundo-branco.png';
import AuthService from '../../../services/authService';
import UtilService from '../../../services/utilService';
import RdStation from '../../../services/rdStation';
import UsuarioService from '../../../services/usuarioService';
import IAuth from '../../../models/auth';

import FormField from '../../../components/FormField';
import Loading from '../../../components/Loading';
import CountdownTimer from '../../../components/CountdownTimer';


type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  redirectTo:any, 
  loading:boolean, 
  loading_resumo:boolean, 
  hasError:boolean, 
  errorMessage: string, 
  show_fields:boolean, 
  oabChangePlano:boolean, 
  numero: string, 
  validade: string, 
  cvv: string, 
  nome_titular: string, 
  cpf_cnpj_cartao: string, 
  cpf_cnpj: string, 
  mesmo_titular_cpf_cnpj: any, 
  nao_possui_cupom: any, 
  cupom_desconto: string, 
  registrarSeInfoJSON: any | null, 
  registrarEnderecoInfoJSON: any | null, 
  registrarPlanoInfoJSON: any | null, 
  registrando:boolean, 
  plano: string | null,
  categoria: string | null,
  valorPlano: number,
  valorDesconto: number,
  valorFinal: number,
  mostrar_resumo:boolean, 
  cupom_bloqueado:boolean, 
  show_modal:boolean, 
  ignoreOAB:boolean, 
  show_reativacao_helper:boolean, 
};

export default class RegistrarCartao extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      redirectTo: false, 
      loading:true, 
      loading_resumo:false, 
      hasError: false, 
      errorMessage: '', 
      show_fields: false, 
      oabChangePlano: false, 
      numero: '', 
      validade: '', 
      cvv: '', 
      nome_titular: '',
      cpf_cnpj_cartao:'',
      cpf_cnpj:'',
      mesmo_titular_cpf_cnpj: true,
      nao_possui_cupom: true,
      cupom_desconto: '',
      registrarSeInfoJSON: null,
      registrarEnderecoInfoJSON: null,
      registrarPlanoInfoJSON: null,
      registrando:false,
      plano: '',
      categoria: '',
      valorPlano: 0,
      valorDesconto: 0,
      valorFinal: 0,
      mostrar_resumo: false,
      cupom_bloqueado: false,
      show_modal: false,
      ignoreOAB: false,
      show_reativacao_helper: false,
    };
  }

  componentDidMount = async () => {
    let isLogged:any = localStorage.getItem('isLogged');
    if(isLogged!==null)
    {
      isLogged = JSON.parse(isLogged);
      if(isLogged)
      {
        this.setState({hasError:false,redirectTo:Rotas.DASHBOARD});
      }
    }

    if(window.innerWidth <= 480)
    {
      this.setState({
        mostrar_resumo: false,
      });      
    }

    let registrarSeInfo = null;
    let registrarSeInfoJSONParse = null;
    try {
      registrarSeInfo = localStorage.getItem('registrarSeInfo') as string;
      registrarSeInfoJSONParse = JSON.parse(registrarSeInfo);
      /*
      console.warn('registrarSeInfoJSON', registrarSeInfoJSONParse);
      */
    }
    catch (error) {
      this.setState({redirectTo:Rotas.REGISTRAR_SE});
    }
    if(registrarSeInfoJSONParse===null || registrarSeInfoJSONParse==='')
    {
      this.setState({redirectTo:Rotas.REGISTRAR_SE});  
      return;    
    }

    let registrarEnderecoInfo = null;
    let registrarEnderecoInfoJSONParse = null;
    try {
      registrarEnderecoInfo = localStorage.getItem('registrarEnderecoInfo') as string;
      registrarEnderecoInfoJSONParse = JSON.parse(registrarEnderecoInfo);
    }
    catch (error) {
      this.setState({redirectTo:Rotas.REGISTRAR_ENDERECO});
    }
    if(registrarEnderecoInfoJSONParse===null || registrarEnderecoInfoJSONParse==='')
    {
      this.setState({redirectTo:Rotas.REGISTRAR_ENDERECO});    
      return;  
    }


    let registrarCategoriaInfo = null;
    let registrarCategoriaInfoJSONParse = null;
    try {
      registrarCategoriaInfo = localStorage.getItem('registrarCategoriaInfo') as string;
      registrarCategoriaInfoJSONParse = JSON.parse(registrarCategoriaInfo);
    }
    catch (error) {
      this.setState({redirectTo:Rotas.REGISTRAR_CATEGORIA});
    }
    if(registrarCategoriaInfoJSONParse===null || registrarCategoriaInfoJSONParse==='')
    {
      this.setState({redirectTo:Rotas.REGISTRAR_CATEGORIA});    
      return;  
    }

    this.setState({categoria:registrarCategoriaInfoJSONParse.categoria});


    let registrarPlanoInfo = null;
    let registrarPlanoInfoJSONParse = null;
    try {
      registrarPlanoInfo = localStorage.getItem('registrarPlanoInfo') as string;
      registrarPlanoInfoJSONParse = JSON.parse(registrarPlanoInfo);
      console.log(registrarPlanoInfoJSONParse);
    }
    catch (error) {
      this.setState({redirectTo:Rotas.REGISTRAR_PLANO});
    }
    if(registrarPlanoInfoJSONParse===null || registrarPlanoInfoJSONParse==='')
    {
      this.setState({redirectTo:Rotas.REGISTRAR_PLANO});    
      return;  
    }

    if(registrarSeInfoJSONParse.cupomLink!=='')
    {
      this.setState({
        nao_possui_cupom: false,
        cupom_desconto: registrarSeInfoJSONParse.cupomLink,
      });
    }

    if(registrarPlanoInfoJSONParse.cupom!=='')
    {
      this.setState({
        nao_possui_cupom: false,
        cupom_bloqueado: true,
        cupom_desconto: registrarPlanoInfoJSONParse.cupom,
      });
    }


    this.setState({
      plano: registrarPlanoInfoJSONParse.plano,
      registrarSeInfoJSON: registrarSeInfoJSONParse,
      registrarEnderecoInfoJSON: registrarEnderecoInfoJSONParse,
      registrarPlanoInfoJSON: registrarPlanoInfoJSONParse,
      loading: false
    });

    let bodyRequest = {
      plano_id: registrarPlanoInfoJSONParse.id_plano,
      cupom: this.state.cupom_desconto,
    };

    let consultaCupomPlano = await UtilService.consultaPlanoCupom(bodyRequest);

    if(registrarPlanoInfoJSONParse.tipo_plano==='convênio oab' && (registrarPlanoInfoJSONParse.id_plano===75 || registrarPlanoInfoJSONParse.id_plano===109))
    {
      this.setState({
        valorPlano: 0,
        valorDesconto: 0,
        valorFinal: 0,
      });
    }
    else
    {
      this.setState({
        valorPlano: consultaCupomPlano.plano_valor,
        valorDesconto: consultaCupomPlano.cupom_valor_desconto,
        valorFinal: consultaCupomPlano.plano_valor-consultaCupomPlano.cupom_valor_desconto,
      });
    }
    
    /*
    this.setState({
      plano: registrarPlanoInfoJSONParse.plano,
      valorPlano: consultaCupomPlano.plano_valor,
      valorDesconto: consultaCupomPlano.cupom_valor_desconto,
      valorFinal: consultaCupomPlano.plano_valor-consultaCupomPlano.cupom_valor_desconto,
      registrarSeInfoJSON: registrarSeInfoJSONParse,
      registrarEnderecoInfoJSON: registrarEnderecoInfoJSONParse,
      registrarPlanoInfoJSON: registrarPlanoInfoJSONParse,
      loading: false
    });
  */

  }  

  showHelpWhatsApp = async () =>{
    let whatsappUrl = 'https://wa.me/553833219333?text=Ol%C3%A1%2C%20gostaria%20de%20reativar%20minha%20conta!';
    const convertionData = {'nome': this.state.registrarSeInfoJSON.nome, 'email': this.state.registrarSeInfoJSON.email, 'telefone': this.state.registrarSeInfoJSON.telefone, 'conversion_name': 'REATIVAR_CADASTRO'};
    const usuarioConversion = await RdStation.usuarioConversion(convertionData);
    this.openInNewTab(whatsappUrl);
  }


  openInNewTab = (url:any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if(newWindow) newWindow.opener = null;
  }

  cleanDocument = (v:string) => {
    let value=v.replace(/\D/g,'');                 
    return value;
  }

  fetchOAB = async () =>{
    let cpfQ = this.state.cpf_cnpj_cartao.replaceAll('.','').replaceAll('-','');
    const response = await fetch(`https://azero.admex.com.br/api/api.asmx/Validar_Advogado?wsKey=9fa8136e5e58f53dc2314c7518b848a6&CPF=${cpfQ}&OAB=`);
    const data = await response.json();
    return data[0];
  }

  efetivarRegistro = async (ignoreOAB = false) => {
    if(this.state.loading)
    {
      return;
    }
    let is_convenio_oab = false;
    let is_convenio_oab_tipo = '';
    
    this.setState({loading:true});

    if(!ignoreOAB && this.state.registrarEnderecoInfoJSON.estado.toUpperCase()==='MG' && this.state.registrarPlanoInfoJSON.tipo_plano==='convênio oab' && (this.state.registrarPlanoInfoJSON.id_plano===75 || this.state.registrarPlanoInfoJSON.id_plano===109))
    {
      let situacao_oab_ok = false;
      try{
        let oabData = await this.fetchOAB();
        if(oabData.Situacao==='Ativo' && (oabData.SituacaoF==='QUITE' || oabData.SituacaoF==='EM DIA'))
        {
          situacao_oab_ok = true;
        }
        else
        {
          situacao_oab_ok = false;
        }
      }
      catch (e) {
        console.warn('error: ', e);
        this.setState({hasError:true,errorMessage:'Registro da OAB não encontrado!', loading: false});
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });    
        return;
      }
      if(!situacao_oab_ok)
      {
        alert('Ocorreu um erro no seu cadastro! Seu registro na OAB possuí pendências!');
        this.setState({show_modal:true, loading: false});
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });    
        return;        
      }
    }
    else{
      this.setState({cupom_desconto: '', nao_possui_cupom: true, loading: true});
    }

    if(this.state.registrarPlanoInfoJSON.tipo_plano==='convênio oab')
    {
      is_convenio_oab = true;
      if(this.state.registrarPlanoInfoJSON.id_plano===109 || this.state.registrarPlanoInfoJSON.id_plano===75)
      {
        is_convenio_oab_tipo = 'basic';
      }
      else
      {
        is_convenio_oab_tipo = 'pro_premium';        
      }
    }


    if(this.state.numero==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o número do cartão', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }

    if(this.state.validade==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha a validade do cartão', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }


    if(this.state.cvv==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o código de segurança do cartão (CVV)', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }


    if(this.state.nome_titular==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o nome do titular', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }


    if(this.state.cpf_cnpj_cartao==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o CPF/CNPJ do titular do cartão', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }

    if(!this.state.mesmo_titular_cpf_cnpj && this.state.cpf_cnpj==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o CPF/CNPJ do usuário', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }

    if(!this.state.nao_possui_cupom && this.state.cupom_desconto==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o cupom de desconto', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }

    let validade_cartao = this.state.validade.split('/');
    let nome_completo = this.state.registrarSeInfoJSON.nome.split(' ');
    let telefone_ddd = this.state.registrarSeInfoJSON.telefone.split(' ');

    let cartaoData = {
      cpf_cnpj: this.cleanDocument(this.state.cpf_cnpj_cartao),
      numero: this.cleanCardNumber(this.state.numero), 
      validade_mes: validade_cartao[0], 
      validade_ano: `20${validade_cartao[1]}`, 
      cvv: this.state.cvv, 
      bandeira: this.getCardFlag(`${this.state.numero}`), 
      nome: this.state.nome_titular,      
      cupom_desconto: this.state.cupom_desconto 
    };

    /*
    if(this.state.registrarPlanoInfoJSON.tipo_plano==='convênio oab' && this.state.registrarPlanoInfoJSON.id_plano===75)
    {
      cartaoData = {
        cpf_cnpj: this.cleanDocument(this.state.cpf_cnpj_cartao),
        numero: this.cleanCardNumber(this.state.numero), 
        validade_mes: validade_cartao[0], 
        validade_ano: `20${validade_cartao[1]}`, 
        cvv: this.state.cvv, 
        bandeira: this.getCardFlag(`${this.state.numero}`), 
        nome: this.state.nome_titular,
        quant_parcela: 12,
        cupom_desconto: this.state.cupom_desconto 
      };
    }
    else
    {
      cartaoData = {
        cpf_cnpj: this.cleanDocument(this.state.cpf_cnpj_cartao),
        numero: this.cleanCardNumber(this.state.numero), 
        validade_mes: validade_cartao[0], 
        validade_ano: `20${validade_cartao[1]}`, 
        cvv: this.state.cvv, 
        bandeira: this.getCardFlag(`${this.state.numero}`), 
        nome: this.state.nome_titular,      
        cupom_desconto: this.state.cupom_desconto 
      };
    }
    */

    let cadastro_request = {
      id_plano: this.state.registrarPlanoInfoJSON.id_plano,
      add_site_profissional: this.state.registrarPlanoInfoJSON.add_site_profissional,
      add_servico_ads: this.state.registrarPlanoInfoJSON.ads_presente,
      add_servico_assistente_virtual: !is_convenio_oab ? this.state.registrarPlanoInfoJSON.assistente_virtual : false,
      add_servico_super_combo: !is_convenio_oab ? this.state.registrarPlanoInfoJSON.super_combo : false,
      add_servico_super_combo_convenio: is_convenio_oab ? this.state.registrarPlanoInfoJSON.super_combo_convenio : false,
      add_servico_filtro_instagram: !is_convenio_oab ? this.state.registrarPlanoInfoJSON.filtro_instagram_presente : false,
      add_servico_site_profissional: !is_convenio_oab ? this.state.registrarPlanoInfoJSON.site_profissional_presente : false,
      add_servico_cartao_interativo: !is_convenio_oab ? this.state.registrarPlanoInfoJSON.cartao_interativo_presente : false,
      filtro_instagram_presente: is_convenio_oab ? this.state.registrarPlanoInfoJSON.filtro_instagram_presente : false,
      site_profissional_presente: is_convenio_oab ? this.state.registrarPlanoInfoJSON.site_profissional_presente : false,
      cartao_interativo_presente: is_convenio_oab ? this.state.registrarPlanoInfoJSON.cartao_interativo_presente : false,
      cpf: this.state.mesmo_titular_cpf_cnpj ? this.cleanDocument(this.state.cpf_cnpj_cartao) : this.cleanDocument(this.state.cpf_cnpj),
      email: this.state.registrarSeInfoJSON.email,
      password: this.state.registrarSeInfoJSON.senha,
      nome_completo: this.state.registrarSeInfoJSON.nome,
      nome: nome_completo[0],
      sobrenome: nome_completo[1],
      cep: this.state.registrarEnderecoInfoJSON.cep,
      endereco: this.state.registrarEnderecoInfoJSON.rua,
      numero: this.state.registrarEnderecoInfoJSON.numero,
      bairro: this.state.registrarEnderecoInfoJSON.bairro,
      complemento: 'Casa',
      cidade: this.state.registrarEnderecoInfoJSON.cidade,
      estado: this.state.registrarEnderecoInfoJSON.estado,
      ddd: this.state.registrarSeInfoJSON.telefone.substring(0,2),
      telefone: this.state.registrarSeInfoJSON.telefone.substring(3,this.state.registrarSeInfoJSON.telefone.length),
      celular: this.state.registrarSeInfoJSON.telefone.substring(3,this.state.registrarSeInfoJSON.telefone.length),
      observacao: '',
      categoria: this.state.categoria,
      cartao: cartaoData,
      convenio_oab: is_convenio_oab,
      convenio_oab_tipo: is_convenio_oab_tipo,
    };
    /*
    localStorage.setItem('registrarCartao', JSON.stringify(cadastro_request));
    this.setState({loading: false, redirectTo:Rotas.REGISTRAR_RESUMO});  
    */
    let usuarioInsert = null
    try{
      usuarioInsert = await UsuarioService.insereUsuario(cadastro_request);
    }
    catch (error) {
      const errors_exception = error.data;
      let key_name = Object.keys(errors_exception.errors)[0];
      if(key_name==='superlogica')
      {
        if(errors_exception.errors[key_name]==='CartÃ£o invÃ¡lido.')
        {
          /*
          alert('Ocorreu um erro com os dados de pagamento','Cartão inválido!');
          */
          this.setState({hasError:true, loading:false, errorMessage:'Cartão inválido!'});
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });    
        }
        else
        {
          /*
          alert('Ocorreu um erro com os dados de pagamento',String(errors_exception.errors[key_name]));
          */
          this.setState({hasError:true, loading:false, errorMessage:String(errors_exception.errors[key_name])});
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });    

        }
      }
      else if(key_name==='medusa')
      {
        let key_medusa = Object.keys(errors_exception.errors[key_name])[0];
        /*
        alert('Ocorreu um erro com '+key_name,String(errors_exception.errors[key_name][key_medusa]));
        */
        this.setState({hasError:true, loading:false, errorMessage:String(errors_exception.errors[key_name][key_medusa])});
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });    

      }
      else
      {
        /*
        alert('Ocorreu um erro com '+key_name,String(errors_exception.errors[key_name]));
        */
        this.setState({hasError:true, loading:false, errorMessage:String(errors_exception.errors[key_name])});

        if(String(errors_exception.errors[key_name]).includes('CPF'))
        {
          await this.setState({show_reativacao_helper: true});
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });    
      }
      this.setState({loading:false});
      return;
    }

    localStorage.setItem('registrarSeInfo', '');
    localStorage.setItem('registrarEnderecoInfo', '');
    localStorage.setItem('registrarPlanoInfo', '');
    localStorage.setItem('registrarCartao', '');

    localStorage.setItem('token', usuarioInsert.token);
    localStorage.setItem('isLogged', JSON.stringify(true));
    localStorage.setItem('loginError', '');

    this.setState({loading:false, redirectTo:Rotas.UPLOAD_LOGO});
  }

  onChangeNumero = (event:any) =>{
    this.setState({numero:event.target.value});
  }

  onChangeValidade = (event:any) =>{
    this.setState({validade:event.target.value});
  }

  onChangeCpfCnpjCartao = (event:any) =>{
    this.setState({cpf_cnpj_cartao:event.target.value});
  }

  onChangeCpfCnpj = (event:any) =>{
    this.setState({cpf_cnpj:event.target.value});
  }

  getCardFlag = (cardnumber:any) =>{
    let cardnumberParam = cardnumber.replace(/[^0-9]+/g, '');

    let cards = {
        'visa'      : /^4[0-9]{12}(?:[0-9]{3})/,
        'mastercard' : /^5[1-5][0-9]{14}/,
        'diners'    : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        'amex'      : /^3[47][0-9]{13}/,
        'discover'  : /^6(?:011|5[0-9]{2})[0-9]{12}/,
        'hipercard'  : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        'elo'        : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        'jcb'        : /^(?:2131|1800|35\d{3})\d{11}/,       
        'aura'      : /^(5078\d{2})(\d{2})(\d{11})$/     
    };

    const keys = Object.keys(cards);
    const values = Object.values(cards);

    for (let i = 0; i < keys.length; i += 1) {
      if(cardnumberParam.match(values[i]))
      {
        return values[i];
      }
    }       

    return 'mastercard';
  }

  cleanCardNumber = (v:string) => {
    let value = v.replace(/\D/g,'');                 
    return value;
  }

  onChangeCupom = async (e:any) => {

    this.setState({loading_resumo:true});
    if(this.state.registrarPlanoInfoJSON.tipo_plano==='convênio oab' && (this.state.registrarPlanoInfoJSON.id_plano===75 || this.state.registrarPlanoInfoJSON.id_plano===109))
    {
      this.setState({
        valorPlano: 0,
        loading_resumo:false,
        valorDesconto: 0,
        valorFinal: 0,
      });
      return; 
    }

    let bodyRequest = {
      plano_id: this.state.registrarPlanoInfoJSON.id_plano,
      cupom: e.target.value,
    };

    let consultaCupomPlano = await UtilService.consultaPlanoCupom(bodyRequest);

    this.setState({
      cupom_desconto: e.target.value,
      loading_resumo:false,
      valorPlano: consultaCupomPlano.plano_valor,
      valorDesconto: consultaCupomPlano.cupom_valor_desconto,
      valorFinal: consultaCupomPlano.plano_valor-consultaCupomPlano.cupom_valor_desconto,
    });
  }

  toggleResumoBlock = () =>{
    if(this.state.mostrar_resumo)
    {
      this.setState({mostrar_resumo:false});
    }
    else
    {
      this.setState({mostrar_resumo:true});
    }
  }

  changePlanoOAB = async () =>{
    localStorage.setItem('ignoreOAB', 's');
    this.setState({hasError:false,redirectTo:Rotas.REGISTRAR_PLANO});
  }

  concordarPlanoAnualOAB = async () =>{
    await this.setState({cupom_desconto:'', nao_possui_cupom: true, show_modal: false});
    this.efetivarRegistro(true);
  }

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <>
        <div className={window.innerWidth <= 480 ? 'block-mobile' : 'block-default' }>
          <div className={this.state.mostrar_resumo ? 'registrar-cartao login-top-desc com-resumo' : 'registrar-cartao login-top-desc sem-resumo'}>
            <Grid className='login block-cartao' verticalAlign='middle'>
              <Grid.Column tablet={12} computer={8}>
                <Segment padded>
                  <Grid columns={1} className='login__title'>
                    <Grid.Column>
                      <Header as='h1'>
                        Registrar <span>cartão de crédito</span>
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <CountdownTimer />
                    </Grid.Column>
                  </Grid>
                  <Grid columns={1}>
                    <Grid.Column>
                      {
                        this.state.hasError ? 
                        <div className='ErrorLogin'>{this.state.errorMessage}</div>
                        : 
                        null
                      }
                      {
                        this.state.oabChangePlano ?
                          <Button
                              className='oabChangePlanoButton'
                              primary
                              size='large'
                              fluid
                              onClick={() => this.changePlanoOAB()}
                            >
                            Gostaria de escolher um de nossos planos?
                          </Button>
                        :
                          null
                      }
                      <Form>
                        <Grid columns={1}>
                          <Grid.Column>
                            <Form.Field className='form-field'>
                              <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                              <label htmlFor='numero'>Número do cartão</label>
                              <InputMask 
                                name='Número do cartão'
                                mask='9999 9999 9999 9999' 
                                id='numero'
                                placeholder='Digite o Número do seu cartão'
                                value={this.state.numero}
                                onChange={this.onChangeNumero}
                              />
                            </Form.Field>
                          </Grid.Column>
                        </Grid>
                        <Grid columns={2}>
                          <Grid.Column>
                            <Form.Field className='form-field'>
                              <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                              <label htmlFor='validade'>Validade</label>
                              <InputMask 
                                name='Validade'
                                mask='99/99' 
                                id='validade'
                                placeholder='Digite a validade do seu cartão'
                                value={this.state.validade}
                                onChange={this.onChangeValidade}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column>
                            <FormField
                              name='CVV'
                              type='text'
                              id='cvv'
                              value={this.state.cvv}
                              placeholder='Digite o CVV do cartão'
                              onChange={(val:string, prevVal:string) => this.setState({cvv:val})}
                            />
                          </Grid.Column>
                        </Grid>                    
                        <Grid columns={1}>
                          <Grid.Column>
                            <FormField
                              name='Nome do titular'
                              type='text'
                              id='nome_titular'
                              value={this.state.nome_titular}
                              placeholder='Digite o nome do titular do cartão'
                              onChange={(val:string, prevVal:string) => this.setState({nome_titular:val})}
                            />
                          </Grid.Column>
                        </Grid>   
                        <Grid columns={1}>
                          <Grid.Column>
                            <Form.Field className='form-field'>
                              <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                              <label htmlFor='cpf_cnpj_cartao'>CPF/CNPJ do Titular</label>
                              <InputMask 
                                name='cpf_cnpj_cartao'
                                mask='999.999.999-99' 
                                id='cpf_cnpj_cartao'
                                placeholder='Digite o CPF/CNPJ do titular do cartão'
                                value={this.state.cpf_cnpj_cartao}
                                onChange={this.onChangeCpfCnpjCartao}
                              />
                            </Form.Field>
                          </Grid.Column>
                        </Grid>             
                        <Grid columns={1}>
                          <Grid.Column>
                            <Checkbox 
                              label='Mesmo titular do cartão de crédito' 
                              id='mesmo_titular_cpf_cnpj'
                              defaultChecked={this.state.mesmo_titular_cpf_cnpj}
                              onChange={(e:any,data:any) => {
                                this.setState({mesmo_titular_cpf_cnpj:data.checked})
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                        {
                          !this.state.mesmo_titular_cpf_cnpj ?
                            <Grid columns={1}>
                              <Grid.Column>
                                <Form.Field className='form-field'>
                                  <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                  <label htmlFor='cpf_cnpj_cartao'>CPF/CNPJ do usuário</label>
                                  <InputMask 
                                    name='cpf_cnpj'
                                    mask='999.999.999-99' 
                                    id='cpf_cnpj'
                                    placeholder='Digite o CPF/CNPJ do usuário'
                                    value={this.state.cpf_cnpj}
                                    onChange={this.onChangeCpfCnpj}
                                  />
                                </Form.Field>
                              </Grid.Column>
                            </Grid> 
                          :
                            null
                        }          
                        <Grid columns={1}>
                          <Grid.Column>
                            <Checkbox 
                              label='Não possuí cupom' 
                              id='nao_possui_cupom'
                              defaultChecked={this.state.nao_possui_cupom}
                              onChange={(e:any,data:any) => {
                                this.setState({nao_possui_cupom:data.checked})
                                if(data.checked)
                                {
                                  this.setState({cupom_desconto:''})
                                }
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                        {
                          !this.state.nao_possui_cupom ?
                            <Grid columns={1}>
                              <Grid.Column>
                                <Form.Field className='form-field'>
                                  <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                                  <label htmlFor='cupom_desconto'>Cupom de desconto</label>
                                  <input
                                    type='text'
                                    id='cupom_desconto'
                                    value={this.state.cupom_desconto}
                                    placeholder='Digite o cupom de desconto'
                                    onBlur={this.onChangeCupom}
                                    disabled={this.state.cupom_bloqueado}
                                    onChange={e => {
                                      this.setState({cupom_desconto:e.target.value})
                                    }}
                                  />
                                </Form.Field>
                              </Grid.Column>
                            </Grid>  
                          :
                            null
                        }

                      </Form>
                      <Grid columns={1}>
                        <Grid.Column>
                          <Button
                            primary
                            size='large'
                            fluid
                            onClick={() => this.efetivarRegistro()}
                          >
                            Concluir
                          </Button>
                        </Grid.Column>
                      </Grid>
                      <Grid columns={1}>
                        <Grid.Column>
                          <Image src={SeloPagarme} centered className='selo-pagarme' />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid>

            <Grid className='login block-resumo' verticalAlign='middle'>
              <Grid.Column tablet={12} computer={8}>
                <Segment padded>
                  <Grid columns={1} className='login__title'>
                    <Grid.Column>
                      <Header as='h1'>
                        <span>Resumo</span>
                        <Icon className='refreshResumo' name='redo' onClick={() => this.onChangeCupom({target:{value:this.state.cupom_desconto}})}/>
                      </Header>
                    </Grid.Column>
                  </Grid>
                  {
                    this.state.loading_resumo ?

                      <Header as='h1' className='titleModal'>
                        <span>Carregando...</span>
                      </Header>
                    :
                      <Grid columns={1}>
                        <Grid.Column>
                          {
                            this.state.plano==='basic' || this.state.plano==='basic_promo_cancel' ?
                              <div className='resumoPlano'>
                                <Header as='h1' className='titleModal'>
                                  <span>Plano Basic</span>
                                </Header>
                                <Header as='h3' className='subTitleModal'>
                                  <span>R$ 29,99</span>
                                </Header>
                                <ul className='listModal'>
                                  <li>300 mídias de notícias editáveis/mês</li>
                                  <li>20 Templates de conteúdo</li>
                                  <li>Suporte</li>
                                  <li>7 dias gratuitos</li>
                                </ul>
                              </div>
                            :
                              null
                          }
                          {
                            this.state.plano==='pro' || this.state.plano==='pro_promo_cancel' ?
                              <div className='resumoPlano'>
                                <Header as='h1' className='titleModal'>
                                  <span>Plano Pro</span>
                                </Header>
                                <Header as='h3' className='subTitleModal'>
                                  <span>R$ 39,99</span>
                                </Header>
                                <ul className='listModal'>
                                  <li>Todos serviços do plano Basic +</li>
                                  <li>250 mídias editáveis de Frases de inspiração/mês</li>
                                  <li>Site Profissional em formato de Story</li>
                                  <li>7 dias gratuitos</li>
                                </ul>
                              </div>
                            :
                              null
                          }
                          {
                            this.state.plano==='pro_promo' ?
                              <div className='resumoPlano'>
                                <Header as='h1' className='titleModal'>
                                  <span>Plano Pro</span>
                                </Header>
                                <Header as='h3' className='subTitleModal'>
                                  <span>R$ 34,99</span>
                                </Header>
                                <ul className='listModal'>
                                  <li>Todos serviços do plano Basic +</li>
                                  <li>250 mídias editáveis de Frases de inspiração/mês</li>
                                  <li>Site Profissional em formato de Story</li>
                                  <li>7 dias gratuitos</li>
                                </ul>
                              </div>
                            :
                              null
                          }
                          {
                            this.state.plano==='premium' ?
                              <div className='resumoPlano'>
                                <Header as='h1' className='titleModal'>
                                  <span>Plano Premium</span>
                                </Header>
                                <Header as='h3' className='subTitleModal'>
                                  <span>R$ 69,99</span>
                                </Header>
                                <ul className='listModal'>
                                  <li>Todos serviços dos planos Basic e Pro +</li>
                                  <li>300 mídias de Datas comemorativas/ano</li>
                                  <li>Sistema de Agendamento Online</li>
                                  <li>7 dias gratuitos</li>
                                </ul>
                              </div>
                            :
                              null
                          }
                          {
                            this.state.plano==='premium_promo' ?
                              <div className='resumoPlano'>
                                <Header as='h1' className='titleModal'>
                                  <span>Plano Premium</span>
                                </Header>
                                <Header as='h3' className='subTitleModal'>
                                  <span>R$ 49,99</span>
                                </Header>
                                <ul className='listModal'>
                                  <li>Todos serviços dos planos Basic e Pro +</li>
                                  <li>300 mídias de Datas comemorativas/ano</li>
                                  <li>Sistema de Agendamento Online</li>
                                  <li>7 dias gratuitos</li>
                                </ul>
                              </div>
                            :
                              null
                          }


                          {
                            this.state.plano==='basic_anual' || this.state.plano==='basic_anual_cancel' ?
                              <div className='resumoPlano'>
                                <Header as='h1' className='titleModal'>
                                  <span>Plano Basic Anual</span>
                                </Header>
                                <Header as='h3' className='subTitleModal'>
                                  <span>R$ 19,99/mês</span>
                                </Header>
                                <ul className='listModal'>
                                  <li>3600 mídias de notícias editáveis/mês</li>
                                  <li>20 Templates de conteúdo</li>
                                  <li>Suporte</li>
                                  <li>7 dias gratuitos</li>
                                </ul>
                              </div>
                            :
                              null
                          }
                          {
                            this.state.plano==='pro_anual' || this.state.plano==='pro_anual_cancel' ?
                              <div className='resumoPlano'>
                                <Header as='h1' className='titleModal'>
                                  <span>Plano Pro Anual</span>
                                </Header>
                                <Header as='h3' className='subTitleModal'>
                                  <span>R$ 29,99/mês</span>
                                </Header>
                                <ul className='listModal'>
                                  <li>Todos serviços do plano Basic +</li>
                                  <li>3000 mídias editáveis de Frases de inspiração/mês</li>
                                  <li>Site Profissional em formato de Story</li>
                                  <li>7 dias gratuitos</li>
                                </ul>
                              </div>
                            :
                              null
                          }
                          {
                            this.state.plano==='pro_promo_anual' ?
                              <div className='resumoPlano'>
                                <Header as='h1' className='titleModal'>
                                  <span>Plano Pro Anual</span>
                                </Header>
                                <Header as='h3' className='subTitleModal'>
                                  <span>R$ 29,99/mês</span>
                                </Header>
                                <ul className='listModal'>
                                  <li>Todos serviços do plano Basic +</li>
                                  <li>3000 mídias editáveis de Frases de inspiração/mês</li>
                                  <li>Site Profissional em formato de Story</li>
                                  <li>7 dias gratuitos</li>
                                </ul>
                              </div>
                            :
                              null
                          }
                          {
                            this.state.plano==='premium_anual' ?
                              <div className='resumoPlano'>
                                <Header as='h1' className='titleModal'>
                                  <span>Plano Premium Anual</span>
                                </Header>
                                <Header as='h3' className='subTitleModal'>
                                  <span>R$ 39,99/mês</span>
                                </Header>
                                <ul className='listModal'>
                                  <li>Todos serviços dos planos Basic e Pro +</li>
                                  <li>300 mídias de Datas comemorativas/ano</li>
                                  <li>Sistema de Agendamento Online</li>
                                  <li>7 dias gratuitos</li>
                                </ul>
                              </div>
                            :
                              null
                          }
                          {
                            this.state.plano==='premium_promo_anual' ?
                              <div className='resumoPlano'>
                                <Header as='h1' className='titleModal'>
                                  <span>Plano Premium Anual</span>
                                </Header>
                                <Header as='h3' className='subTitleModal'>
                                  <span>R$ 39,99/mês</span>
                                </Header>
                                <ul className='listModal'>
                                  <li>Todos serviços dos planos Basic e Pro +</li>
                                  <li>300 mídias de Datas comemorativas/ano</li>
                                  <li>Sistema de Agendamento Online</li>
                                  <li>7 dias gratuitos</li>
                                </ul>
                              </div>
                            :
                              null
                          }
                          {
                            this.state.valorDesconto>0 ?
                              <div className='resumoPlano'>
                                <Header as='h1' className='titleModal'>
                                  <span>Desconto de</span>
                                </Header>
                                <Header as='h3' className='subTitleModal'>
                                  <span>R$ {this.state.valorDesconto.toFixed(2)}</span>
                                </Header>
                                <Header as='h4' className='titleModal'>
                                  <span> no primeiro mês</span>
                                </Header>
                              </div>
                            :
                              null
                          }
                          <div className='resumoPlanoFinal'>
                            <div className='resumoPlano'>
                              <Header as='h1' className='titleModal'>
                                <span>Valor final</span>
                              </Header>
                              <Header as='h3' className='subTitleModal'>
                                <span>R$ {this.state.valorFinal.toFixed(2)}</span>
                              </Header>
                            </div>
                          </div>

                        </Grid.Column>
                      </Grid>
                  }
                </Segment>
              </Grid.Column>
            </Grid>
          </div>
        </div>
        <Modal
          onClose={() => this.setState({show_modal:false})}
          onOpen={() => this.setState({show_modal:true})}
          open={this.state.show_modal} >
          <Modal.Header className='ModalErrorOAB'>Atenção!</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <Header as='h1' className='titleModal'>
                <span>Temos uma oportunidade para você: Plano Basic Anual</span>
              </Header>
              <Header as='h3' className='subTitleModal'>
                <span>7 dias grátis!</span>
              </Header>
              <ul className='listModal'>
                <li>300 Mídias de notícias editáveis/mês;</li>
                <li>20 templates de conteúdo;</li>
                <li>Suporte;</li>
                <li>Após 07 dias R$19,99 por mês cobrado Anualmente;</li>
                <li>+1 bônus selecionado.</li>
              </ul>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={() => this.setState({show_modal:false})}>
              Agora não
            </Button>
            <Button
              className="btnConcordar"
              content='Concordar'
              labelPosition='right'
              icon='checkmark'
              onClick={() => this.concordarPlanoAnualOAB()}
              positive
            />
          </Modal.Actions>
        </Modal>
        
      </>
    );
  }
};

