import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Loader,
  Segment,
  Dimmer,
  Image,
  Header,
  Button, 
  Form, 
  Icon,
  Modal,
  Checkbox,
} from 'semantic-ui-react';
import InputMask from 'react-input-mask';

import ReCAPTCHA from 'react-google-recaptcha';

import Rotas from '../../../utils/rotas';
import '../login.scss';
import './registrar.scss';
import Logo from '../../../assets/imgs/logo.png';
import LogoBranca from '../../../assets/imgs/logo-branca.png';
import Celulares from '../../../assets/imgs/celulares.png';

import Background from '../../../assets/imgs/site-gratis-background.jpg';
import AuthService from '../../../services/authService';
import RdStation from '../../../services/rdStation';
import IAuth from '../../../models/auth';

import FormField from '../../../components/FormField';
import UsuarioService from '../../../services/usuarioService';
import Loading from '../../../components/Loading';


import AndroidStoreIMG from '../../../assets/imgs/android-store.png';
import IosStoreIMG from '../../../assets/imgs/ios-store.png';


type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  redirectTo:any, 
  loading:boolean, 
  hasError:boolean, 
  errorMessage: string, 
  nome: string, 
  email: string, 
  telefone: string, 
  cpf: string,
  senha: string, 
  confirmarSenha: string, 
  showSenha: boolean,
  cupomLink: any, 
  concordo_termos: boolean,
  show_modal:boolean, 
  isVerified: boolean,
  handleOnChange: boolean,
};

export default class RedirecionarStores extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      redirectTo: false, 
      loading:true, 
      hasError: false, 
      errorMessage: '', 
      nome: '', 
      email: '', 
      telefone: '', 
      senha: '',
      confirmarSenha: '',
      cpf: '',
      showSenha: false,
      cupomLink: '',
      concordo_termos: false,
      show_modal: false,
      isVerified: false,
      handleOnChange: false,
    };
  }
  
  handleOnChange = (value: any) => {
    console.log('Captcha value:', value);
    this.setState({isVerified: true});
  }

  onChangeCpfCnpj = (event:any) =>{
    this.setState({cpf:event.target.value});
  }

  componentDidMount = async () => {
    this.setState({loading:false});
  }

  validateEmail = (email:string) =>{
    /* eslint-disable-next-line */
    let re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let emailisvalid = re.test(String(email).toLowerCase());
    return emailisvalid;
  }

  getOnlyNumber = (v:string) => {
    let value=v.replace(/\D/g,'');                 
    return value;
  }

  handleNextStep = async () => {
    this.setState({hasError:false, loading: true});
    console.log('nextStep');
    /*
    * Validate Info
    */
    if(!this.state.concordo_termos)
    {
      this.setState({hasError:true,errorMessage:'Você precisa preencher os termos de uso', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.nome==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o nome', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    let nome_completo = this.state.nome.split(' ');
    if(nome_completo.length===1 || nome_completo[1]===undefined || nome_completo[1]==='')
    {
      this.setState({hasError:true,errorMessage:'Você deve informar nome e sobrenome', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;  
    }
    if(this.state.email==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o e-mail', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(!this.validateEmail(this.state.email))
    {
      this.setState({hasError:true,errorMessage:'E-mail inválido', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.telefone==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o telefone', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    console.log('telefone', this.getOnlyNumber(this.state.telefone).length);
    if(this.getOnlyNumber(this.state.telefone).length<11)
    {
      this.setState({hasError:true,errorMessage:'Preencha um telefone válido', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.senha==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha a senha', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.confirmarSenha==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha a confirmação da senha', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.senha!==this.state.confirmarSenha)
    {
      this.setState({hasError:true,errorMessage:'As senhas não se coincidem', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }

    const registerInfo = {
      nome: this.state.nome,
      email: this.state.email,
      telefone: this.state.telefone,
      senha: this.state.senha,
      cupomLink: this.state.cupomLink
    }

    console.log('registerInfo', registerInfo);

    const convertionData = {'nome': this.state.nome, 'email': this.state.email, 'telefone': this.state.telefone, 'conversion_name': 'CADASTRO_WEB_INICIO'};
    const usuarioConversion = await RdStation.usuarioConversion(convertionData);

    console.log('registrarSeInfo', registerInfo);
    localStorage.setItem('registrarSeInfo', JSON.stringify(registerInfo));
    this.setState({loading: false, redirectTo:Rotas.REGISTRAR_ENDERECO});    
  }

  onChangeTelefone = (event:any) =>{
    this.setState({telefone:event.target.value});
  }

  toggleShowSenha = async (currentState:boolean) =>{
    this.setState({showSenha:!currentState});    
  }

  efetivarRegistro = async () => {
    if(this.state.loading)
    {
      return;
    }
    
    this.setState({loading:true});

    let cartaoData = {
      cpf_cnpj: this.state.cpf,
      numero: '', 
      validade_mes: '', 
      validade_ano: '', 
      cvv: '', 
      bandeira: '', 
      nome: this.state.nome,
      cupom_desconto: '',
      quant_parcela: '',
    };

    let nome_completo = this.state.nome.split(' ');
    let cadastro_request = {
      force_service: true,
      id_plano: '108',
      id_vendedor: '',
      email: this.state.email,
      password: this.state.senha,
      nome_completo: this.state.nome,
      nome: nome_completo[0],
      sobrenome: nome_completo[1],
      cpf: this.state.cpf,
      cep: '39400-066',
      endereco: ' Av. Ovídio de Abreu',
      numero: '466',
      bairro: 'Centro',
      complemento: 'Casa',
      cidade: 'Montes Claros',
      estado: 'MG',
      ddd: this.state.telefone.substring(0,2),
      telefone: this.state.telefone.substring(3,this.state.telefone.length),
      celular: `(${this.state.telefone.substring(0,2)}) ${this.state.telefone.substring(3,this.state.telefone.length)}`,
      observacao: '',
      categoria: 'advogado',
      cartao: cartaoData,
    };

    /*
    localStorage.setItem('registrarCartao', JSON.stringify(cadastro_request));
    this.setState({loading: false, redirectTo:Rotas.REGISTRAR_RESUMO});  
    */
    let usuarioInsert = null
    try{
      usuarioInsert = await UsuarioService.insereUsuario(cadastro_request);
    }
    catch (error) {
      const errors_exception = error.data;
      let key_name = Object.keys(errors_exception.errors)[0];
      if(key_name==='superlogica')
      {
        if(errors_exception.errors[key_name]==='CartÃ£o invÃ¡lido.')
        {
          /*
          alert('Ocorreu um erro com os dados de pagamento','Cartão inválido!');
          */
          this.setState({hasError:true, loading:false, errorMessage:'Cartão inválido!'});
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });    
        }
        else
        {
          /*
          alert('Ocorreu um erro com os dados de pagamento',String(errors_exception.errors[key_name]));
          */
          this.setState({hasError:true, loading:false, errorMessage:String(errors_exception.errors[key_name])});
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });    

        }
      }
      else if(key_name==='medusa')
      {
        let key_medusa = Object.keys(errors_exception.errors[key_name])[0];
        /*
        alert('Ocorreu um erro com '+key_name,String(errors_exception.errors[key_name][key_medusa]));
        */
        this.setState({hasError:true, loading:false, errorMessage:String(errors_exception.errors[key_name][key_medusa])});
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });    

      }
      else
      {
        /*
        alert('Ocorreu um erro com '+key_name,String(errors_exception.errors[key_name]));
        */
        this.setState({hasError:true, loading:false, errorMessage:String(errors_exception.errors[key_name])});
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });    
      }
      this.setState({loading:false});
      return;
    }
    console.log('usuarioInsert', usuarioInsert);


    localStorage.setItem('token', usuarioInsert.token);
    localStorage.setItem('isLogged', JSON.stringify(true));
    localStorage.setItem('loginError', '');

    this.setState({loading:false, redirectTo:Rotas.REDIRECIONAR_STORES});
  }


  openLink = (link: string) => {
    window.open(link, '_blank');
}


  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    return (
        <Grid className='login' verticalAlign='middle'>
          <Grid.Column tablet={12} computer={8} className="formRegisterGrid">
            <Segment padded>
            <Image src={Logo} centered />

              <Grid columns={1} className='login__title'>
                <Grid.Column>
                  <Header as='h1' className='headerregistro'>
                    <span>Para baixar o app selecione a sua loja</span>
                  </Header>
                </Grid.Column>
              </Grid>
              <Grid columns={1}>
                <Grid.Column>
                    <Grid columns={2}>
                        <Grid.Column className='colImg android'>
                            <Image className='ImgStore' src={AndroidStoreIMG} centered onClick={() => this.openLink('https://play.google.com/store/apps/details?id=com.advogarwebmobile&hl=pt_BR')} />
                        </Grid.Column>
                        <Grid.Column className='colImg ios'>
                            <Image className='ImgStore' src={IosStoreIMG} centered onClick={() => this.openLink('https://apps.apple.com/br/app/advogar-web/id1483803142')} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            </Segment>
          </Grid.Column>

        </Grid>
        
    );
  }
};

