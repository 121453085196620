import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Loader,
  Segment,
  Dimmer,
  Image,
  Header,
  Button, 
  Form, 
  Icon,
  Checkbox,
} from 'semantic-ui-react';
import InputMask from 'react-input-mask';

import Rotas from '../../../utils/rotas';
import '../login.scss';
import './registrar.scss';
import Logo from '../../../assets/imgs/logo.png';
import UtilService from '../../../services/utilService';
import RdStation from '../../../services/rdStation';
import UsuarioService from '../../../services/usuarioService';
import IAuth from '../../../models/auth';

import FormField from '../../../components/FormField';
import Loading from '../../../components/Loading';


type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  redirectTo:any, 
  loading:boolean, 
  hasError:boolean, 
  errorMessage: string, 
  registrarCartaoJSON: any | null, 
  plano: string | null,
  valorPlano: number,
  valorDesconto: number,
  valorFinal: number,
};

export default class RegistrarResumo extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      redirectTo: false, 
      loading:true, 
      hasError: false, 
      errorMessage: '', 
      registrarCartaoJSON: null,
      plano: '',
      valorPlano: 0,
      valorDesconto: 0,
      valorFinal: 0,
    };
  }

  componentDidMount = async () => {
    let isLogged:any = localStorage.getItem('isLogged');
    if(isLogged!==null)
    {
      isLogged = JSON.parse(isLogged);
      if(isLogged)
      {
        this.setState({hasError:false,redirectTo:Rotas.DASHBOARD});
      }
    }

    let registrarCartaoInfo = null;
    let registrarCartaoInfoJSONParse = null;
    try {
      registrarCartaoInfo = localStorage.getItem('registrarCartao') as string;
      registrarCartaoInfoJSONParse = JSON.parse(registrarCartaoInfo);
      /*
      console.warn('registrarSeInfoJSON', registrarSeInfoJSONParse);
      */
    }
    catch (error) {
      this.setState({redirectTo:Rotas.REGISTRAR_CARTAO});
    }    

    let bodyRequest = {
      plano_id: registrarCartaoInfoJSONParse.id_plano,
      cupom: registrarCartaoInfoJSONParse.cartao.cupom_desconto,
    };
    let registrarPlanoInfo = null;
    let registrarPlanoInfoJSONParse = null;
    try {
      registrarPlanoInfo = localStorage.getItem('registrarPlanoInfo') as string;
      registrarPlanoInfoJSONParse = JSON.parse(registrarPlanoInfo);
      /*
      console.warn('registrarPlanoInfoJSONParse', registrarPlanoInfoJSONParse);
      */
    }
    catch (error) {
      this.setState({redirectTo:Rotas.REGISTRAR_PLANO});
    }
    console.log('registrarPlanoInfoJSONParse', registrarPlanoInfoJSONParse);

    let consultaCupomPlano = await UtilService.consultaPlanoCupom(bodyRequest);

    this.setState({
      plano: registrarPlanoInfoJSONParse.plano,
      registrarCartaoJSON: registrarCartaoInfoJSONParse,
      valorPlano: consultaCupomPlano.plano_valor,
      valorDesconto: consultaCupomPlano.cupom_valor_desconto,
      valorFinal: consultaCupomPlano.plano_valor-consultaCupomPlano.cupom_valor_desconto,
      loading: false
    });

  }  


  efetivarRegistro = async () => {
    if(this.state.loading)
    {
      return;
    }
    
    this.setState({loading:true});

    let usuarioInsert = null
    try{
      usuarioInsert = await UsuarioService.insereUsuario(this.state.registrarCartaoJSON);
    }
    catch (error) {
      const errors_exception = error.data;
      let key_name = Object.keys(errors_exception.errors)[0];
      if(key_name==='superlogica')
      {
        if(errors_exception.errors[key_name]==='CartÃ£o invÃ¡lido.')
        {
          /*
          alert('Ocorreu um erro com os dados de pagamento','Cartão inválido!');
          */
          this.setState({hasError:true,errorMessage:'Cartão inválido!'});
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });    
        }
        else
        {
          /*
          alert('Ocorreu um erro com os dados de pagamento',String(errors_exception.errors[key_name]));
          */
          this.setState({hasError:true,errorMessage:String(errors_exception.errors[key_name])});
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });    

        }
      }
      else if(key_name==='medusa')
      {
        let key_medusa = Object.keys(errors_exception.errors[key_name])[0];
        /*
        alert('Ocorreu um erro com '+key_name,String(errors_exception.errors[key_name][key_medusa]));
        */
        this.setState({hasError:true,errorMessage:String(errors_exception.errors[key_name][key_medusa])});
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });    

      }
      else
      {
        /*
        alert('Ocorreu um erro com '+key_name,String(errors_exception.errors[key_name]));
        */
        this.setState({hasError:true,errorMessage:String(errors_exception.errors[key_name])});
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });    
      }
      this.setState({loading:false});
      return;
    }
    console.log('usuarioInsert', usuarioInsert);

    localStorage.setItem('registrarSeInfo', '');
    localStorage.setItem('registrarEnderecoInfo', '');
    localStorage.setItem('registrarPlanoInfo', '');
    localStorage.setItem('registrarCartao', '');

    localStorage.setItem('token', usuarioInsert.token);
    localStorage.setItem('isLogged', JSON.stringify(true));
    localStorage.setItem('loginError', '');

    this.setState({loading:false, redirectTo:Rotas.UPLOAD_LOGO});
  }

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <>
        <Grid className='login' verticalAlign='middle'>
          <Grid.Column tablet={12} computer={8}>
            <Segment padded>
              <Grid centered columns={1} padded='vertically'>
                <Grid.Column>
                  <Image src={Logo} centered />
                </Grid.Column>
              </Grid>
              <Grid columns={1} className='login__title'>
                <Grid.Column>
                  <Header as='h1'>
                    <span>Resumo</span>
                  </Header>
                </Grid.Column>
              </Grid>
              <Grid columns={1}>
                <Grid.Column>
                  {
                    this.state.hasError ? 
                    <div className='ErrorLogin'>{this.state.errorMessage}</div>
                    : 
                    null
                  }
                  {
                    this.state.plano==='basic' || this.state.plano==='basic_promo_cancel' ?
                      <div className='resumoPlano'>
                        <Header as='h1' className='titleModal'>
                          <span>Plano Basic</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 29,99</span>
                        </Header>
                        <ul className='listModal'>
                          <li>300 mídias de notícias editáveis/mês</li>
                          <li>20 Templates de conteúdo</li>
                          <li>Suporte</li>
                          <li>7 dias gratuitos</li>
                        </ul>
                      </div>
                    :
                      null
                  }
                  {
                    this.state.plano==='pro' || this.state.plano==='pro_promo_cancel' ?
                      <div className='resumoPlano'>
                        <Header as='h1' className='titleModal'>
                          <span>Plano Pro</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 39,99</span>
                        </Header>
                        <ul className='listModal'>
                          <li>Todos serviços do plano Basic +</li>
                          <li>250 mídias editáveis de Frases de inspiração/mês</li>
                          <li>Site Profissional em formato de Story</li>
                          <li>7 dias gratuitos</li>
                        </ul>
                      </div>
                    :
                      null
                  }
                  {
                    this.state.plano==='pro_promo' ?
                      <div className='resumoPlano'>
                        <Header as='h1' className='titleModal'>
                          <span>Plano Pro</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 34,99</span>
                        </Header>
                        <ul className='listModal'>
                          <li>Todos serviços do plano Basic +</li>
                          <li>250 mídias editáveis de Frases de inspiração/mês</li>
                          <li>Site Profissional em formato de Story</li>
                          <li>7 dias gratuitos</li>
                        </ul>
                      </div>
                    :
                      null
                  }
                  {
                    this.state.plano==='premium' ?
                      <div className='resumoPlano'>
                        <Header as='h1' className='titleModal'>
                          <span>Plano Premium</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 69,99</span>
                        </Header>
                        <ul className='listModal'>
                          <li>Todos serviços dos planos Basic e Pro +</li>
                          <li>300 mídias de Datas comemorativas/ano</li>
                          <li>Sistema de Agendamento Online</li>
                          <li>7 dias gratuitos</li>
                        </ul>
                      </div>
                    :
                      null
                  }
                  {
                    this.state.plano==='premium_promo' ?
                      <div className='resumoPlano'>
                        <Header as='h1' className='titleModal'>
                          <span>Plano Premium</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 49,99</span>
                        </Header>
                        <ul className='listModal'>
                          <li>Todos serviços dos planos Basic e Pro +</li>
                          <li>300 mídias de Datas comemorativas/ano</li>
                          <li>Sistema de Agendamento Online</li>
                          <li>7 dias gratuitos</li>
                        </ul>
                      </div>
                    :
                      null
                  }


                  {
                    this.state.plano==='basic_anual' || this.state.plano==='basic_anual_cancel' ?
                      <div className='resumoPlano'>
                        <Header as='h1' className='titleModal'>
                          <span>Plano Basic Anual</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 19,99/mês</span>
                        </Header>
                        <ul className='listModal'>
                          <li>3600 mídias de notícias editáveis/mês</li>
                          <li>20 Templates de conteúdo</li>
                          <li>Suporte</li>
                          <li>7 dias gratuitos</li>
                        </ul>
                      </div>
                    :
                      null
                  }
                  {
                    this.state.plano==='pro_anual' || this.state.plano==='pro_anual_cancel' ?
                      <div className='resumoPlano'>
                        <Header as='h1' className='titleModal'>
                          <span>Plano Pro Anual</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 29,99/mês</span>
                        </Header>
                        <ul className='listModal'>
                          <li>Todos serviços do plano Basic +</li>
                          <li>3000 mídias editáveis de Frases de inspiração/mês</li>
                          <li>Site Profissional em formato de Story</li>
                          <li>7 dias gratuitos</li>
                        </ul>
                      </div>
                    :
                      null
                  }
                  {
                    this.state.plano==='pro_promo_anual' ?
                      <div className='resumoPlano'>
                        <Header as='h1' className='titleModal'>
                          <span>Plano Pro Anual</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 29,99/mês</span>
                        </Header>
                        <ul className='listModal'>
                          <li>Todos serviços do plano Basic +</li>
                          <li>3000 mídias editáveis de Frases de inspiração/mês</li>
                          <li>Site Profissional em formato de Story</li>
                          <li>7 dias gratuitos</li>
                        </ul>
                      </div>
                    :
                      null
                  }
                  {
                    this.state.plano==='premium_anual' ?
                      <div className='resumoPlano'>
                        <Header as='h1' className='titleModal'>
                          <span>Plano Premium Anual</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 39,99/mês</span>
                        </Header>
                        <ul className='listModal'>
                          <li>Todos serviços dos planos Basic e Pro +</li>
                          <li>300 mídias de Datas comemorativas/ano</li>
                          <li>Sistema de Agendamento Online</li>
                          <li>7 dias gratuitos</li>
                        </ul>
                      </div>
                    :
                      null
                  }
                  {
                    this.state.plano==='premium_promo_anual' ?
                      <div className='resumoPlano'>
                        <Header as='h1' className='titleModal'>
                          <span>Plano Premium Anual</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ 39,99/mês</span>
                        </Header>
                        <ul className='listModal'>
                          <li>Todos serviços dos planos Basic e Pro +</li>
                          <li>300 mídias de Datas comemorativas/ano</li>
                          <li>Sistema de Agendamento Online</li>
                          <li>7 dias gratuitos</li>
                        </ul>
                      </div>
                    :
                      null
                  }

                  {
                    this.state.valorDesconto>0 ?
                      <div className='resumoPlano'>
                        <Header as='h1' className='titleModal'>
                          <span>Desconto de</span>
                        </Header>
                        <Header as='h3' className='subTitleModal'>
                          <span>R$ {this.state.valorDesconto.toFixed(2)}</span>
                        </Header>
                      </div>
                    :
                      null
                  }
                  <div className='resumoPlanoFinal'>
                    <div className='resumoPlano'>
                      <Header as='h1' className='titleModal'>
                        <span>Valor final</span>
                      </Header>
                      <Header as='h3' className='subTitleModal'>
                        <span>R$ {this.state.valorFinal.toFixed(2)}</span>
                      </Header>
                    </div>
                  </div>

                  <Grid columns={1}>
                    <Grid.Column>
                      <Button
                        primary
                        size='large'
                        fluid
                        onClick={() => this.efetivarRegistro()}
                      >
                        Finalizar cadastro
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>
        
      </>
    );
  }
};

