import React, { useState, useEffect } from 'react';
import { NavLink, useParams, useHistory, Redirect} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Breadcrumb,
  Container,
  Menu,
  Input,
  Button,
  Icon,
  Modal,
  Grid,
  Header,
  Form,
  Select,
  List, 
  Image,
  Card 
} from 'semantic-ui-react';
import Rotas, { breadCrumb } from '../../utils/rotas';
import constants from '../../utils/constantes';
import './index.scss';
import MenuAdvogar from '../dashboard/components/menu';
import EditorAreaService from '../../services/editorAreaService';
import Loading from '../../components/Loading';


type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {   
  id:string, 
  redirectTo:any, 
  loading:boolean, 
  modalFiltroOpen:boolean, 
  searchString: string, 
  currentPage:number, 
  areasEditor:any[], 
  areasEditorFiltradas:any[], 
  nextPage:string | null, 
};

export default class Subareas extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      id: this.props.match.params.id, 
      redirectTo: false, 
      modalFiltroOpen:false, 
      loading:true, 
      searchString: '', 
      currentPage: 1, 
      areasEditor: [], 
      areasEditorFiltradas: [], 
      nextPage: ''
    };
  }

  componentDidMount = async () => {
    console.log('componentDidMount Subareas');
    await this.handleGetMore(parseInt(this.state.id, 10), this.state.currentPage);
    this.setState({loading:false});
  }

  componentWillUnmount = () =>{
    /*
    this.setState({
      id: this.props.match.params.id, 
      modalFiltroOpen:false, 
      loading:true, 
      searchString: '', 
      currentPage: 1, 
      areasEditor: [], 
      areasEditorFiltradas: [], 
      nextPage: ''
    });
    */
  }

  handleGetMore = async (id_area:number, page: number) => {
    const areasResponse = await EditorAreaService.getSubAreas(id_area, page);
    const currentAux = this.state.areasEditor;
    const {currentPage} = this.state;
    const newArr = [...currentAux, ...areasResponse.results];
    this.setState({currentPage: currentPage+1, nextPage: areasResponse.next, areasEditor: newArr, areasEditorFiltradas: newArr});
  };

  handleOpenSubArea = async (id_area:number) =>{  
    this.setState({loading:true});    
    const page = 1;
    const subareasResponse = await EditorAreaService.getSubAreas(id_area, page);
    const modelosResponse = await EditorAreaService.getModelosArea(id_area, page);
    if(subareasResponse.results.length>0)
    {
      this.setState({redirectTo:Rotas.SUBAREAS.replace(':id',String(id_area))});
      
      await this.reloadParams();
      await this.handleGetMore(id_area, this.state.currentPage);
      this.setState({loading:false});
    }
    else if(modelosResponse.results.length>0)
    {
      this.setState({redirectTo:Rotas.MIDIAS_AREA.replace(':id',String(id_area)), loading:false});
    }
    else
    {
      alert('Não há modelos ou subáreas disponíveis!');
      this.setState({loading:false});
    }
  };

  handleSearch = async (e:any)=>{
    this.setState({loading:true});
    console.log('handleSearch', this.state.searchString);
    let results = [];
    if(this.state.searchString.trim().toUpperCase()==='')
    {
      const {areasEditor, areasEditorFiltradas} = this.state;
      results = areasEditor;
      this.setState({areasEditorFiltradas:results});
    }
    else
    {
      for(let i=0; i<this.state.areasEditor.length;i+= 1)
      {
        if(this.state.areasEditor[i].titulo.toUpperCase().includes(this.state.searchString.trim().toUpperCase()))
        {
          results.push(this.state.areasEditor[i]);
        }
      }
      this.setState({areasEditorFiltradas:results});
    }
    this.setState({loading:false});
  };

  reloadParams()
  {
    this.setState({
      id: this.props.match.params.id, 
      modalFiltroOpen:false, 
      redirectTo: false, 
      loading:true, 
      searchString: '', 
      currentPage: 1, 
      areasEditor: [], 
      areasEditorFiltradas: [], 
      nextPage: ''
    });

  };

  render(){
    const modalButton = (
      <Button inverted>
        <Icon name='list alternate' />
        Filtros
      </Button>
    );
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <Container fluid className='subareas page' key={`subareas-container-${this.state.id}`}>
        <Menu stackable fluid borderless className='menu-advogar' attached='top'>
          <Menu.Menu className='menu-button'>
            <Menu.Item>
              <MenuAdvogar />
            </Menu.Item>
          </Menu.Menu>
          <Menu.Item className='menu-breadcrumb'>
            <Breadcrumb>
              {breadCrumb[Rotas.CRIAR_MIDIAS].map(bread =>
                bread.link ? (
                  <>
                    <Breadcrumb.Section
                      as={NavLink}
                      to={bread.key}
                      active={bread.active}
                    >
                      {bread.content}
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                  </>
                ) : (
                  <Breadcrumb.Section active={bread.active}>
                    {bread.content}
                  </Breadcrumb.Section>
                )
              )}
            </Breadcrumb>
          </Menu.Item>
          <Menu.Menu className='busca'>
            <Menu.Item>
              <Input action={{
                  icon: 'search',
                  onClick: this.handleSearch
                }} 
                value={this.state.searchString}
                id='searchMenu'
                onChange={(event)=>{this.setState({searchString:event.target.value})}}
                placeholder='Pesquisar...'/>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Container fluid className='container-body'>
          <div className='list-cards-container'>
            <div className='list-cards'>
              {this.state.areasEditorFiltradas.map((area, i) => {
                return (
                  <Card onClick={()=>{this.handleOpenSubArea(parseInt(area.id, 10))}} key={`subareas-${area.id}`}>
                    <Image src={`${constants.BASE_URL}${area.imagem}`} wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>{area.titulo}</Card.Header>
                    </Card.Content>
                    <Card.Content extra>
                      {area.descricao || 'Sem descricao'}
                    </Card.Content>
                  </Card>
                )})
              }
            </div>
          </div>
          {
            this.state.nextPage!=null ?
              <div className='list-cards-footer'>
                <Button as='button' fluid primary onClick={() => {this.handleGetMore(parseInt(this.state.id, 10),this.state.currentPage)}}>
                  Carregar mais itens
                </Button>
              </div>
              :
              null
          }

        </Container>
      </Container>
    );
  }
};

