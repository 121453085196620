import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Loader,
  Segment,
  Dimmer,
  Image,
  Header,
  Button, 
  Form, 
  Icon,
  Modal,
  Checkbox,
} from 'semantic-ui-react';
import InputMask from 'react-input-mask';

import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

import Rotas from '../../../utils/rotas';
import '../login.scss';
import './registrar.scss';
import Logo from '../../../assets/imgs/logo.png';
import AuthService from '../../../services/authService';
import RdStation from '../../../services/rdStation';
import IAuth from '../../../models/auth';

import FormField from '../../../components/FormField';
import Loading from '../../../components/Loading';
import CountdownTimer from '../../../components/CountdownTimer';


type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  redirectTo:any, 
  loading:boolean, 
  hasError:boolean, 
  errorMessage: string, 
  nome: string, 
  email: string, 
  telefone: string, 
  senha: string, 
  confirmarSenha: string, 
  showSenha: boolean,
  cupomLink: any, 
  concordo_termos: boolean,
  show_modal:boolean, 
  isVerified: boolean,
  handleOnChange: boolean,
};

export default class RegistrarSe extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      redirectTo: false, 
      loading:true, 
      hasError: false, 
      errorMessage: '', 
      nome: '', 
      email: '', 
      telefone: '', 
      senha: '',
      confirmarSenha: '',
      showSenha: false,
      cupomLink: '',
      concordo_termos: false,
      show_modal: false,
      isVerified: false,
      handleOnChange: false,
    };
  }
  
  handleOnChange = (value: any) => {
    console.log('Captcha value:', value);
    this.setState({isVerified: true});
  }

  componentDidMount = async () => {
    
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://www.googletagmanager.com/gtag/js?id=G-0QE4WSK3EZ';
    document.body.appendChild(s);

    const s2 = document.createElement('script');
    s2.type = 'text/javascript';
    s2.async = true;
    s2.innerHTML = 'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments)}gtag("js", new Date());gtag("config", "G-0QE4WSK3EZ");';
    document.body.appendChild(s2);
    
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/8fd044c7-cd74-4220-8d5c-25c205a6c373-loader.js';
    document.body.appendChild(script);
    
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.async = true;
    script2.src = 'https://crm.adwebapp.com.br/monitoring/1724.js';
    document.body.appendChild(script2);


    const script3 = document.createElement('script');
    script3.type = 'text/javascript';
    script3.async = true;
    script3.src = 'https://mobile.advogar.site/150/012b41b1747f9ecac8b5d5825076033f13e25d29312f61354ca0039772c2186d/conversion-scale4.js';
    document.body.appendChild(script3);

    let isLogged:any = localStorage.getItem('isLogged');
    if(isLogged!==null)
    {
      isLogged = JSON.parse(isLogged);
      if(isLogged)
      {
        this.setState({hasError:false,redirectTo:Rotas.DASHBOARD});
      }
    }

    let previousData = localStorage.getItem('registrarSeInfo');
    if(previousData!==null && previousData!=='')
    {
      let previousDataJson = JSON.parse(previousData);
      this.setState({
        nome: previousDataJson.nome,
        email: previousDataJson.email,
        telefone: previousDataJson.telefone,
      });

    }
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));
    console.log('cupom', params.get('cupom'));
    if(params.get('cupom')!==null && params.get('cupom')!==undefined)
    {
      this.setState({cupomLink:params.get('cupom')});
    }
    else
    {
      this.setState({cupomLink:''});
    }
    this.setState({loading:false});
  }

  validateEmail = (email:string) =>{
    /* eslint-disable-next-line */
    let re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let emailisvalid = re.test(String(email).toLowerCase());
    return emailisvalid;
  }

  getOnlyNumber = (v:string) => {
    let value=v.replace(/\D/g,'');                 
    return value;
  }

  handleNextStep = async () => {
    this.setState({hasError:false, loading: true});
    console.log('nextStep');
    /*
    * Validate Info
    */
    if(!this.state.concordo_termos)
    {
      this.setState({hasError:true,errorMessage:'Você precisa preencher os termos de uso', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.nome==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o nome', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    let nome_completo = this.state.nome.split(' ');
    if(nome_completo.length===1 || nome_completo[1]===undefined || nome_completo[1]==='')
    {
      this.setState({hasError:true,errorMessage:'Você deve informar nome e sobrenome', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;  
    }
    if(this.state.email==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o e-mail', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(!this.validateEmail(this.state.email))
    {
      this.setState({hasError:true,errorMessage:'E-mail inválido', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.telefone==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha o telefone', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    console.log('telefone', this.getOnlyNumber(this.state.telefone).length);
    if(this.getOnlyNumber(this.state.telefone).length<11)
    {
      this.setState({hasError:true,errorMessage:'Preencha um telefone válido', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.senha==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha a senha', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.confirmarSenha==='')
    {
      this.setState({hasError:true,errorMessage:'Preencha a confirmação da senha', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.senha!==this.state.confirmarSenha)
    {
      this.setState({hasError:true,errorMessage:'As senhas não se coincidem', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }

    const registerInfo = {
      nome: this.state.nome,
      email: this.state.email,
      telefone: this.state.telefone,
      senha: this.state.senha,
      cupomLink: this.state.cupomLink
    }


    let convertionData = null;

    let url_origem = new URLSearchParams(this.props.location.search).get('origem');
    
    if(url_origem!==null)
    {
      convertionData = {'nome': this.state.nome, 'email': this.state.email, 'telefone': this.state.telefone, 'conversion_name': 'CADASTRO_WEB_INICIO', 'origem': url_origem};
    }
    else
    {
      convertionData = {'nome': this.state.nome, 'email': this.state.email, 'telefone': this.state.telefone, 'conversion_name': 'CADASTRO_WEB_INICIO'};
    }

    const usuarioConversion = await RdStation.usuarioConversion(convertionData);
    
    await axios.post('https://mobile.advogar.site/api/webhook/bitrix/',{
                    'leads': [
                        {
                            'email': this.state.email,
                            'name': this.state.nome,
                            'personal_phone': this.state.telefone,
                            'mobile_phone':  this.state.telefone,
                            'tags': ['cadastro']
                        }
                    ]
                }).then((response) => {
                }).catch((error) => {
                    console.log(error);
                });
    let url_adweb = 'https://mobile.advogar.site/api/crm/webhook/151/012b41b1747f9ecac8b5d5825076033f13e25d29312f61354ca0039772c2186d';
    /* eslint-disable-next-line */
    let formDataObject = {'nome': this.state.nome, 'email': this.state.email, 'telefone': this.state.telefone};
    await fetch(url_adweb, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body:  JSON.stringify(formDataObject)
    })
        .then(response => response.json())
        .then(data => {
            console.log('Resposta do servidor:', data);
        })
        .catch(error => {
            console.error('Erro ao enviar os dados:', error);
        });


    localStorage.setItem('registrarSeInfo', JSON.stringify(registerInfo));
    this.setState({loading: false, redirectTo:Rotas.REGISTRAR_ENDERECO});    
  }

  onChangeTelefone = (event:any) =>{
    this.setState({telefone:event.target.value});
  }

  toggleShowSenha = async (currentState:boolean) =>{
    this.setState({showSenha:!currentState});    
  }

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <>
        <Grid className='login registrar-informacacoes login-top-desc' verticalAlign='middle'>
          <Grid.Column tablet={12} computer={8}>
            <Segment padded>
              <Grid columns={1} className='login__title'>
                <Grid.Column>
                  <Header as='h1'>
                    Registrar <span>informações do usuário</span>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <CountdownTimer seconds={300}/>
                </Grid.Column>
              </Grid>
              <Grid columns={1}>
                <Grid.Column>
                  {
                    this.state.hasError ? 
                    <div className="ErrorLogin">{this.state.errorMessage}</div>
                    : 
                    null
                  }
                  <Form>
                    <Grid columns={1}>
                      <Grid.Column>
                        <FormField
                          name='Nome'
                          type='text'
                          id='nome'
                          value={this.state.nome}
                          placeholder='Nome completo'
                          onChange={(val:string, prevVal:string) => this.setState({nome:val})}
                        />
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1}>
                      <Grid.Column>
                        <FormField
                          name='E-mail'
                          type='email'
                          id='email'
                          value={this.state.email}
                          placeholder='Digite o endereço de e-mail'
                          onChange={(val:string, prevVal:string) => this.setState({email:val})}
                        />
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1}>
                      <Grid.Column>
                        <Form.Field className='form-field'>
                          <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                          <label htmlFor='telefone'>Telefone</label>
                          <InputMask 
                            name='Telefone'
                            mask="99 99999-9999" 
                            id='telefone'
                            value={this.state.telefone}
                            placeholder='Digite o seu telefone com DDD'
                            onChange={this.onChangeTelefone}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1}>
                      <Grid.Column>
                        <FormField
                          name='Senha'
                          type={this.state.showSenha ? 'text' : 'password'}
                          id='senha'
                          placeholder='Digite sua senha'
                          onChange={(val:string, prevVal:string) => this.setState({senha:val})}
                        />
                        {
                          !this.state.showSenha ?
                            <Icon className='toggleSenha' name='eye' onClick={() => this.toggleShowSenha(this.state.showSenha )}/>
                          :
                            <Icon className='toggleSenha' name='eye slash' onClick={() => this.toggleShowSenha(this.state.showSenha )}/>
                        }
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1}>
                      <Grid.Column>
                        <FormField
                          name='Confirmar senha'
                          type='password'
                          id='confirmarSenha'
                          placeholder='Digite a confirmação da senha'
                          onChange={(val:string, prevVal:string) => this.setState({confirmarSenha:val})}
                        />
                      </Grid.Column>
                    </Grid>
                  </Form>
                  <Grid columns={1}>
                    <Grid.Column>
                      <Checkbox 
                        label='Li e concordo com os termos de uso' 
                        id='concordo_termos'
                        defaultChecked={this.state.concordo_termos}
                        checked={this.state.concordo_termos}
                        onChange={(e:any,data:any) => {
                          this.setState({concordo_termos:false})
                        }}
                        onClick={() => this.setState({show_modal:true})}
                      />
                    </Grid.Column>
                  </Grid>
                  <div className='containerRecaptchaInfo'>
                        <div className='positionRecaptchaInfo'>
                          <p className='descRecapInfo'> Para avançar verifique se voce é um humano: </p>
                          <div className='containerIframeRecaptchaInfo'>
                            <ReCAPTCHA className='recaptchaInfo' sitekey="6LccvekfAAAAAK43rNtB5QtHEKBs2RdM8jSNOhty" onChange={this.handleOnChange}/>
                          </div>
                        </div>
                      </div>
                  <Grid columns={1}>
                    <Grid.Column>
                      <Button
                        primary
                        size='large'
                        fluid
                        disabled = {!this.state.isVerified}
                        onClick={() => this.handleNextStep()}
                      >
                        Registrar
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
          <Modal
            onClose={() => this.setState({show_modal:false})}
            onOpen={() => this.setState({show_modal:true})}
            open={this.state.show_modal}
            className='modalTermos'
          >
            <Modal.Header>Termos de uso</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <div className='termosText'>
                  <p>1. Do Aceite e Das Alterações:</p>
                  <p>a.  O uso dos serviços ofertados pela Adweb está expressamente condicionado à aceitação deste termo. Os USUÁRIOS aceitam, sem ressalvas, todas as regras descritas neste instrumento. O presente termo estabelece as CONDIÇÕES GERAIS DE USO para o sistema Adweb e produtos auxiliares;</p>
                  <p>b.  As cláusulas ou itens abaixo poderão sofrer alterações, unilateralmente e a qualquer tempo, para atender determinadas exigências, inclusive de terceiros, devendo a sua discordância ser informada por escrito a Embratec Inovações pelo e-mail contato@embratec.org. </p>

                  <p>2. POLÍTICA DE PRIVACIDADE</p>
                  <p>A Ferramenta coleta as seguintes informações de identificação pessoal fornecidas no ato da compra: nome, CPF/CNPJ, endereço (para posterior cobrança), e-mail e telefone.</p>
                  <p>2.1 As informações são fornecidas via preenchimento de formulário pelo Usuário no ato da contratação do serviço, ficando este responsável pela sua correção e atualização.</p>
                  <p>2.2 As informações fornecidas serão usadas para comunicação, notificação e cobrança dos usuários, estando a ADWEB autorizada a utilizá-las também para a operação de envio de e-mails, como o envio de Newsletter, comunicado de lançamentos, novidades relacionadas à Ferramenta. O Usuário poderá cancelar a assinatura de recebimento de e-mails a qualquer momento, sempre mediante pagamento da parcela referente ao mês da solicitação.</p>
                  <p>2.3 A ADWEB manterá os registros de acesso, sob sigilo, em ambiente controlado e de segurança, por pelo menos 6 meses, nos termos do Art. 15 da Lei nº 12.965/2014 (Marco Civil da Internet).</p>
                  <p>2.4 Apenas funcionários da ADWEB poderão acessar as informações pessoais do Usuário, cuja divulgação para terceiros não será realizada sem autorização.</p>
                  <p>3. SEGURANÇA</p>
                  <p>3.1 Todos os dados inseridos na Ferramenta são sigilosos e protegidos através de criptografia. A nossa equipe de desenvolvedores faz o possível para manter a Ferramenta segura, mas não podemos garantir uma segurança 100% e como todo empreendimento online, estamos sujeitos a riscos.</p>
                  <p>4. CONEXÃO</p>
                  <p>A fim de desfrutar dos serviços oferecidos pela ADWEB, o Usuário deve garantir uma conexão contínua e rápida com a Internet. O Usuário é unicamente responsável por obter e pagar por sua conexão com a Internet, que permitirá que ele desfrute da plataforma.</p>
                  <p>O Usuário reconhece que a qualidade da conexão da Internet depende, entre outros fatores, do provedor de acesso à Internet e do provedor de infraestrutura de Internet, do volume de outros usos que o cliente faz na banda disponível em cada momento e também da qualidade do equipamento conector em sua posse.</p>
                  <p>A ADWEB não é responsável por quaisquer falhas e/ou problemas na visualização atribuídos a uma conexão de Internet defeituosa, interrompida ou lenta ou com problemas atribuídos ao hardware do Usuário.</p>
                  <p>5. SERVIÇO</p>
                  <p>5.1  O serviço é disponibilizado através do aplicativo Adweb App.</p>

                  <p>5.2  Dentro do aplicativo serão comercializados produtos independentes, podendo o usuário contratar todos ou apenas aqueles do seu interesse.</p>
                  <p>5.3  O Web Site compreende uma plataforma em formato de stories que será personalizada pelo usuário, cuja URL ficará disponível para ser inserida nas redes sociais.</p>
                  <p>5.4  O usuário poderá contratar pacotes de Mídias de conteúdo, de notícias ou datas comemorativas, em conjunto ou separadamente. </p>
                  <p>5.5  O editor será comercializado em categorias, podendo  usuário contratar as categorias de acordo sua necessidade.</p>
                  <p>5.6 O acesso será concedido após o pagamento ser identificado, os dados de acesso serão enviados via e-mail.</p>
                  <p>5.7 O usuário autoriza a Adweb lhe ofertar outros produtos por ela desenvolvidos.</p>

                  <p>5.8 O Usuário tem acesso 24 (vinte e quatro) horas por dia, 07 (sete) dias por semana à plataforma enquanto estiver com o pagamento de seu plano em dia.</p>

                  <p>5.9 Todo o conteúdo da plataforma ADWEB APP é desenvolvido por profissionais competentes que buscam oferecer o melhor produto aos assinantes, no entanto não garantimos os resultados nas mídias sociais, pois os mesmos dependem exclusivamente da forma como é publicado, configurado e propagado.</p>
                  <p>5.10  O aplcativo ADWEB e todo o conteúdo visualizado por intermédio do serviço ADWEB APP destinam-se exclusivamente para uso profissional a benefício do próprio assinante e não pode, em hipótese alguma, ser comercializado, bem como não pode ser compartilhados com profissionais que não tenham vínculo formal com a mesma instituição jurídica do assinante. Durante sua assinatura do serviço, a ADWEB concede a você um direito limitado, não exclusivo e intransferível para acessar o aplicativo e obter acesso ao conteúdo da ADWEB. Exceto pelo descrito acima, nenhum outro direito, titularidade ou participação lhe é concedido. Você concorda em não utilizar o serviço para compartilhar publicamente ou comercializar o conteúdo, seja parcial ou total.</p>
                  <p>5.11 O aplicativo ADWEB APP inclusive os produtos comercializados, são atualizados regularmente. Além disso, diferentes aspectos do serviço são testados de forma contínua, incluindo o site, as interfaces de usuário, os recursos promocionais e a disponibilidade do conteúdo da ADWEB.</p>
                  <p>5.12 As mídias de conteúdo da ADWEB estão disponíveis para download, mediante as limitações da quantidade de downloads de cada plano. ESSA DISPONIBILIDADE ESTÁ SUJEITA A LIMITAÇÕES, INCLUSIVE RESTRIÇÕES SOBRE O NÚMERO DE DOWNLOADS MENSAIS POR CONTA. DOWNLOADS DO MESMO CONTEÚDO MAIS DE UMA VEZ É CONTABILIZADO NORMALMENTE NA LIMITAÇÃO DE DOWNLOADS MENSAIS, INDEPENDENTE SE FOR REPETIDO OU NÃO.</p>
                  <p>5.13  Você concorda em usar o serviço ADWEB, incluindo todos os recursos e funcionalidades associadas de acordo com as leis, regras e regulamentos aplicáveis ou outras restrições de uso do serviço ou conteúdo previstas nas mesmas. Você também concorda em não arquivar, reproduzir, distribuir, modificar, exibir, executar, publicar, licenciar ou criar trabalhos derivados, colocar à venda ou utilizar (exceto nas formas expressamente autorizadas por estes Termos de uso) o conteúdo e as informações contidas ou obtidas do serviço ADWEB ou por meio deste. Você também concorda em não contornar, remover, alterar, desativar, degradar ou adulterar quaisquer das proteções de conteúdo do serviço ADWEB, usar qualquer robô, spider, scraper ou outros meios automatizados para acessar o serviço ADWEB, descompilar, executar engenharia reversa ou desmembrar qualquer software ou outros produtos ou processos acessíveis pelo serviço ADWEB, inserir qualquer código ou produto ou manipular o conteúdo do serviço ADWEB de qualquer forma ou usar métodos de data mining, coleta de dados ou extração de dados. Além disso, você concorda em não fazer upload, publicar, enviar por e-mail, comunicar ou transmitir de qualquer forma qualquer material designado para interromper, destruir ou limitar a funcionalidade de qualquer software, hardware ou equipamento de telecomunicações associado ao serviço ADWEB, incluindo vírus de software, código, arquivos ou programas. A ADWEB poderá cancelar ou restringir seu uso do serviço se você violar estes Termos de uso ou se envolver no uso ilegal ou fraudulento do serviço.</p>
                  <p>5.14 A ADWEB pode adicionar, interromper, alterar, remover ou suspender qualquer conteúdo ou produto a seu critério, visando sempre obter o melhor desempenho de seus Usuários, incluindo recursos, funcionalidades,  especificações técnicas e de produtos, áreas de pesquisa, etc.</p>
                  <p>5.15 Alguns conteúdos poderão ser removidos da ADWEB sem notificação prévia aos Usuários. </p>
                  <p>6. PAGAMENTO</p>
                  <p>6.1 O pagamento da sua assinatura ADWEB ocorre via Cartão de Crédito: O Usuário que optar por essa modalidade, registrará seu cartão de crédito, do qual o preço pelo acesso pode ser cobrado, dependendo do plano escolhido, na condição à vista. A sua assinatura será renovada automaticamente até que seja cancelada. Para utilizar o serviço ADWEB você precisa ter acesso à Internet, bem como fornecer uma ou mais Formas de Pagamento. “Forma de pagamento” refere-se a uma forma de pagamento atualizada, válida e aceitável, que poderá ser atualizada periodicamente, e que poderá incluir o pagamento por meio da sua conta com terceiros. A menos que cancele sua assinatura antes da data de faturamento da próxima mensalidade, você nos autoriza a renovar e cobrar o preço da assinatura da mensalidade usando a sua Forma de pagamento (consulte “Cancelamento” abaixo).</p>
                  <p>6.2 Poderemos oferecer vários planos de assinatura, inclusive planos promocionais especiais. Alguns planos de assinatura podem possuir condições e limitações diferentes, que serão reveladas no momento de sua inscrição ou em outras comunicações disponibilizadas a você. Você pode encontrar detalhes específicos a respeito da sua assinatura da ADWEB acessando o nosso site.</p>
                  <p>6.3 Ciclo de cobrança. O preço da assinatura do serviço ADWEB e todos os outros encargos incorridos por você em relação ao uso do serviço, tais como impostos e possíveis taxas de transação, serão cobrados na forma de pagamento utilizada no ato do cadastro. Em alguns casos, a data de pagamento poderá ser alterada, por exemplo, se a Forma de pagamento não puder ser cobrada ou se a sua assinatura paga começar em um dia que não existe em um determinado mês. A ADWEB poderá solicitar antecipadamente uma autorização do valor de sua assinatura ou cobranças relacionadas ao serviço de sua Forma de pagamento de várias formas, inclusive a autorização de até aproximadamente um mês de serviço, no ato de sua inscrição. A primeira cobrança/faturamento ocorre no ato da inscrição, podendo este valor ser promocional para os primeiros 30 dias.</p>
                  <p>6.4 Formas de pagamento. Para utilizar o serviço ADWEB você precisa fornecer uma ou mais Formas de pagamento. Você pode atualizar as suas Formas de pagamento através do contato do suporte ou pelo próprio aplicativo acessando a aba “Suporte”. Nós também podemos atualizar as suas Formas de pagamento utilizando informações fornecidas pelas respectivas instituições financeiras e/ou pelos prestadores de serviços de meios de pagamento. Após qualquer atualização, você nos autoriza a continuar a cobrar a(s) respectiva(s) Forma(s) de pagamento. Você nos autoriza a cobrar de qualquer Forma de pagamento associada à sua conta caso a sua Forma de pagamento principal seja negada ou não esteja mais disponível para nós para o pagamento do valor da assinatura e você concorda que as Formas de pagamento disponíveis tanto para operações de crédito ou boleto poderão ser processadas de ambas as formas. Você continua responsável por todos os valores não pagos. Se um pagamento não for liquidado com sucesso, devido à perda de validade, insuficiência de fundos ou qualquer outro motivo, e você não cancelar a sua conta, poderemos suspender o seu acesso ao serviço até que consigamos fazer a cobrança de uma Forma de pagamento válida. Para algumas Formas de pagamento, as instituições financeiras e/ou os prestadores de serviço de meios de pagamento poderão cobrar determinadas taxas, como taxas sobre transações financeiras internacionais ou outras taxas relacionadas à Forma de pagamento. A cobrança de impostos locais pode variar de acordo com a Forma de pagamento usada. Consulte a instituição financeira da sua forma de pagamento para obter mais detalhes.</p>
                  <p>6.5 Alterações de preços e planos de assinatura. De tempos em tempos, poderemos alterar nossos planos de assinatura e os preços de nosso serviço. Os valores de assinatura poderão ser revisados periodicamente para melhor adequação ao contínuo aprimoramento de nosso serviço. Nós também poderemos ajustar o valor de sua assinatura anualmente, ou com maior frequência, conforme permitido pela legislação vigente, de acordo com a inflação estabelecida pelo Índice Geral de Preços do Mercado (IGP-M), publicado pela Fundação Getúlio Vargas, ou outro índice equivalente aplicável ao serviço ADWEB APP. Quaisquer alterações de preço ou alterações em nossos planos de assinatura serão aplicadas somente 30 dias após a notificação enviada a você.</p>
                  <p>6.6 O pagamento da ADWEB é feito em regra através de MENSALIDADES.</p>
                  <p>6.7 O meios de pagamento disponível é exclusivamente cartão de crédito.</p>
                  <p>6.8 O não acesso à plataforma e/ou não utilização dos serviços ofertados na Ferramenta não isentará o Usuário do pagamento da mensalidade, pois o pagamento é devido pela simples disponibilização do serviço.</p>
                  <p>6.9 Sendo o valor do plano cobrado via cartão de crédito, a modalidade acarretará em pagamento automático todo mês e será constado na fatura do cartão.</p>
                  <p>6.10 Você pode ser solicitado, durante o processo de inscrição, a fornecer as informações do seu cartão de crédito.</p>
                  <p>6.11 Ao cadastrar seu cartão de crédito, poderá ser realizada uma cobrança no valor de até $1,00 para validação do cartão de crédito com a sua administradora de cartão de crédito. O valor cobrado será estornado num período de até 72 horas.</p>
                  <p>6.12 Como Usuário do acesso, você concorda que sejam cobrados no seu cartão de crédito todas as taxas e impostos sobre vendas e quaisquer outras despesas nas quais possa incorrer relacionadas ao uso da ferramenta ADWEB APP.</p>
                  <p>6.13 Todas as despesas serão faturadas em seu cartão de crédito, de acordo com a forma de pagamento escolhida em nosso site. Quaisquer taxas ou encargos bancários decorrentes da contratação aqui tratada serão de sua responsabilidade.</p>
                  <p>6.14 Decorrido o período contratado, seu acesso será renovado automaticamente. Você concorda que sejam debitados os valores decorrentes da renovação, responsabilizando-se por manter fundos necessários para o pagamento quando na época correta.</p>
                  <p>6.15 Caso você altere suas informações para faturamento, as mesmas deverão ser atualizadas junto ao nosso banco de dados.</p>
                  <p>6.16 Ao oferecer as suas informações do cartão de crédito, você está reconhecendo e concordando que podemos manter as informações do mesmo em nossos bancos de dados, até que você encerre sua conta.</p>
                  <p>6.17 Ao oferecer informações do seu cartão de crédito a Adweb, você concorda que consultará os termos e condições que são impostos pelo emissor do seu cartão de crédito para requisitos e limitações de notificação sobre a sua responsabilidade em caso de perda, roubo ou uso não autorizado do seu cartão, bem como informações relacionadas ao limite de crédito e de uso do mesmo.</p>
                  <p>6.18 Você concorda que será o único responsável pelo pagamento de todas as quantias faturadas. Você concorda que não fornecerá nenhuma informação de cartão de crédito que não esteja em seu nome.</p>
                  <p>6.19 Todos os preços listados estão sujeitos a alteração por parte da ABWEB a qualquer momento, sem que haja a necessidade de prévia notificação.</p>
                  <p>7. BLOQUEIOS</p>
                  <p>7.1 O atraso ou impossibilidade de cobrança da mensalidade, poderá resultar no bloqueio imediato do acesso ao aplicativo, sem aviso prévio.</p>
                  <p>7.2 A liberação ocorrerá somente quando houver atualização do método de pagamento e cobrança da mensalidade em questão.</p>
                  <p>7.3 Caso verificado que o aplicativo foi utilizado/disponibilizado sem o devido pagamento e não havendo pedido de cancelamento da assinatura, o valor referente ao período utilizado poderá ser cobrado por telefone, e-mail, correspondência e/ou ação judicial, com acréscimo de correção monetária, juros legais a partir da data de faturamento.</p>
                  <p>7.4 As contas na ADWEB são pessoais e intransferíveis.</p>
                  <p>7.5 Você concorda que não autorizará que outras pessoas utilizem sua conta e concorda que irá manter a confidencialidade e segurança de suas informações de registro.</p>
                  <p>7.6 O saldo de créditos para adquirir conteúdos (posts) mensais é renovado a cada 30 dias, a contar da data de assinatura, e não é cumulativo. Sendo assim o saldo de créditos expira ao final de cada ciclo de cobrança.</p>
                  <p>7.7 Caso haja cancelamento da assinatura, o Usuário poderá continuar acessando o aplicativo e usufruindo do saldo de créditos remanescente até o final do ciclo da cobrança vigente. Após, o acesso será automaticamente suspenso e o saldo de créditos será expirado. </p>
                  <p>8. REEMBOLSO</p>
                  <p>8.1 Nós oferecemos 07 (sete) dias para os novos assinantes usarem nosso aplicativo como prazo de garantia incondicional, e caso não gostem do nosso produto poderão solicitar reembolso integral dentro do período supracitado.</p>
                  <p>8.2 O cancelamento dentro do período de garantia, 07 dias, não implica no reembolso automático. Caso o assinante queira, poderá solicitá-lo diretamente com nosso adorável time de suporte.</p>
                  <p>8.3 Na extensão permitida pelas leis aplicáveis, o pagamento realizado no ato do cadastro pode ser 100% reembolsado dentro do prazo de garantia – 7 dias – caso o Usuário solicite. E após o período de 07 (sete) dias a ADWEB não oferece reembolsos ou créditos por períodos de assinatura utilizados parcialmente ou por conteúdo não utilizado.</p>
                  <p>8.4 Você concorda que, após o período de 07 (sete) dias, nenhuma cobrança é reembolsável. Ou seja, a partir do prazo de garantia o Usuário poderá usufruí-lo ao longo do período contratado.</p>
                  <p>9. CANCELAMENTO DA ASSINATURA</p>
                  <p>9.1 Você pode cancelar a renovação da sua assinatura Adweb a qualquer momento, sempre mediante pagamento da mensalidade vigente ao mês da solicitação. Não realizamos reembolsos do pagamento de parcelas vencidas e vincendos, mesmo após o cancelamento até o final do período da mensalidade já paga. Na extensão permitida pelas leis aplicáveis, os pagamentos não são reembolsáveis e a Adweb não oferece reembolsos ou créditos por períodos de assinatura utilizados parcialmente ou por conteúdo não utilizado. Para cancelar, envie um e-mail para contato@embratec.org e siga as instruções de cancelamento. Se você cancelar sua assinatura, sua conta será automaticamente encerrada ao fim do período da cobrança em andamento.</p>
                  <p>9.2 Nos planos com Site o cancelamento implicará na retirada imediata do Site do ar.</p>
                  <p>9.3 A cobrança das parcelas não será interrompida mediante a solicitação de cancelamento da assinatura pelo Usuário junto à equipe da Adweb, apenas interrompe a renovação automática da próxima mensalidade. Caso não haja uma solicitação de cancelamento, a mensalidade será renovada automaticamente a cada período.</p>
                  <p>9.4 Com o pedido de cancelamento da assinatura, será cessada a renovação da cobrança da próxima mensalidade, não havendo qualquer reembolso dos pagamentos de parcelas vencidas e vincendos. O cadastro poderá penalizado (ver “Penalidades” abaixo) e ser impedido de realizar novas assinaturas devido ao consumo de má fé por múltiplas vezes do período gratuito.</p>
                  <p>10. ATUALIZAÇÃO DOS PLANOS</p>
                  <p>10.1 Cada usuário poderá assinar apenas 01 plano e caso queira atualizar seu plano, deverá assinar o novo plano e cancelar o plano antigo. Caso não haja cancelamento do plano anterior, por parte do usuário, a equipe da Adweb poderá cancelar o plano contratado há mais tempo e manter apenas o plano assinado mais recentemente. As regras do cancelamento após assinatura de um novo plano pelo mesmo Usuário seguirão em conformidade à cláusula de cancelamento deste presente termo, não havendo reembolso nem cobrança proporcional.</p>
                  <p>11. CUPOM DE DESCONTO</p>
                  <p>11.1 O cupom de desconto entregue pela Adweb poderão ser utilizados apenas 01 (uma) vez e limitado para um único CPF/CNPJ, ou seja, é intransferível e não é acumulativo. O cupom de desconto só pode ser ativado por novas assinaturas e a validade máxima é de 01 (um) mês. Caso o assinante não manifeste o interesse de cancelar a assinatura antes do segundo mês, o valor integral da mensalidade será cobrado.</p>
                  <p>12. CONVÊNIOS E PARCERIAS</p>
                  <p>Os convênios e parcerias realizados entre a Adweb e outras empresas serão regidos por contrato próprio entre a pessoa jurídica Embratec e a instituição conveniada.</p>
                  <p>13. USO ILEGAL OU ANTIÉTICO DO CONTEÚDO E DA FERRAMENTA PELO USUÁRIO</p>
                  <p>13.1 É proibida a utilização do conteúdo e do aplicativo por freelances, agências de marketing e qualquer pessoa com intenção de revender o conteúdo produzido pela ADWEB. Os cadastros classificados nesta categoria de revenda, profissionais de marketing ou quaisquer outros tipos de Pessoas, Físicas ou Jurídicas, senão profissionais habilitados da área ao qual se destina o nosso produto – Posts – em questão, terão seus cadastros bloqueados e as assinaturas poderão ser canceladas definitivamente. No tocante aos valores em moeda corrente já pagos não serão reembolsados a título de reparação dos danos e violação dos nossos termos.</p>
                  <p>13.2 Nós não nos responsabilizamos pelo uso indevido do nosso conteúdo nas mídias sociais, ou qualquer outro veículo compatível com o uso dos mesmos, incluindo o uso inábil para divulgação abusiva, que tenham caráter difamatório, discriminatório, obsceno, ofensivo, ameaçador, abusivo, vexatório, prejudicial, que contenha expressões de ódio contra pessoas ou grupos, ou que estimule pornografia infantil, pornografia explícita ou violenta, conteúdo que possa ser danoso a menores, que contenha insultos ou ameaças religiosas ou raciais, ou que incentive danos morais (incluindo os corporais) e patrimoniais, ou que possa violar qualquer direito de terceiro.</p>
                  <p>13.3 Também não nos responsabilizamos pela conduta dos assinantes ao utilizarem nossos conteúdos, recomendamos que desfrutem do mesmo em harmonia e de acordo com o Código de Ética vigente.</p>
                  <p>13.4 Além disso, o Usuário concorda que, ao usar a plataforma ADWEB, não irá:</p>
                  <p>a) Violar qualquer regra contida nestes Termos e Condições de Uso;</p>
                  <p>b) Praticar falsidade, assim entendidas a falsidade de informações (i.e: divulgação proposital e voluntária de informações que o Usuário saiba ser falsa ou que sejam notoriamente falsas) e a falsidade ideológica;</p>
                  <p>c) Publicar ou transmitir qualquer conteúdo abusivo ou ofensivo nos comentários;</p>
                  <p>d) Cometer fraude;</p>
                  <p>e) Violar ou infringir direitos de propriedade intelectual, direitos fiduciários ou contratuais, direitos de privacidade ou publicidade de outros;</p>
                  <p>f) Propagar, distribuir ou transmitir códigos destrutivos, quer tenham ou não causado danos reais;</p>
                  <p>g) Infringir o Código de Ética vigente e aplicável a sua profissão;</p>
                  <p>h) Reproduzir, replicar, copiar, alterar, modificar, criar obras derivativas a partir de, vender ou revender qualquer um dos serviços da ADWEB ou qualquer parte deles, incluindo imagens, textos, tutoriais, ou as informações ou dados contidos nos serviços da ADWEB APP.</p>
                  <p>i) Transmitir conteúdo que não pertence ao Usuário ou que ele não tenha direito de publicar ou distribuir, seja sob lei ou contrato;</p>
                  <p>j) Acessar a Ferramenta sem autorização, por meio de práticas de “hacking”, “password mining” ou qualquer outro meio fraudulento ou que represente violação a direito de terceiros;</p>
                  <p>k) Realizar ou incentivar quaisquer atividades ilegais;</p>
                  <p>l) Deixar de cumprir com quaisquer leis, normas, regras, princípios e regulamentações aplicáveis.</p>
                  <p>13.5 A violação de qualquer uma das regras aqui postas resultará no bloqueio do acesso à Ferramenta, sem prejuízo de eventuais cobranças perdas e danos.</p>
                  <p>13.6 Senhas e acesso à conta. O assinante que criou a conta na ADWEB e cuja Forma de pagamento é cobrada pelo serviço (“Proprietário da Conta”) possui acesso e controle sobre a conta ADWEB, sendo responsável por qualquer atividade que ocorre em sua conta ADWEB. Para manter o controle sobre a conta e evitar que qualquer pessoa possa acessá-la (o que incluiria informações dos títulos assistidos da conta), o Proprietário da conta deve manter o controle sobre os aparelhos compatíveis com a ADWEB utilizados para acessar o serviço e não revelar a ninguém a senha ou os detalhes da Forma de pagamento associada à conta. Você é responsável por atualizar e manter exatas as informações fornecidas à ADWEB relativas à sua conta. A ADWEB poderá cancelar ou suspender a sua conta para proteger você ou a ADWEB contra falsidade ideológica ou outras atividades fraudulentas.</p>
                  <p>14. SUPORTE</p>
                  <p>14.1 A plataforma ADWEB disponibiliza uma Central de Ajuda para apoiar e permitir auto-suporte para uso do aplicativo ADWEB APP.</p>
                  <p>15. PENALIDADES</p>
                  <p>15.1 Qualquer conduta contrária ao previsto nestes Termos de Uso poderá ser punida:</p>
                  <p>– com a remoção das preferências e de determinado CONTEÚDO;</p>
                  <p>– com a suspensão imediata do acesso à conta do USUÁRIO;</p>
                  <p>– com o bloqueio da conta do USUÁRIO por tempo indeterminado na ADWEB;</p>
                  <p>tudo a critério exclusivo da ADWEB, sem prejuízo de poderem ser tomadas as ações legais cabíveis pela própria ADWEB ou por terceiros.</p>
                  <p>15.2 Nos casos de suspensão do acesso à conta do USUÁRIO, preferências e conteúdos relacionadas ao cadastrados em questão serão bloqueados definitivamente e nenhuma alteração poderá ser realizada nos dados cadastrais, dados de pagamento ou nos conteúdos obtidos, tampouco poderá ser feito qualquer resgate de conteúdo na respectiva conta.</p>
                  <p>15.3 Nos casos de bloqueio a conta do cadastro do USUÁRIO, todos os conteúdos da plataforma serão automaticamente bloqueados, não assistindo ao USUÁRIO, por essa razão, qualquer direito de indenização ou ressarcimento.</p>
                  <p>15.4 Caso a ADWEB suspeite ou tenha indícios de que o USUÁRIO está usando mecanismos para fraudar o sistema, a ADWEB reserva-se o direito de suspender imediatamente o acesso à conta do USUÁRIO suspeito de fraude e, eventualmente, bloquear em definitivo a conta do USUÁRIO, sendo certo que todos os direitos de acesso já pago pelo USUÁRIO poderá ser retido pela ADWEB, como ressarcimento parcial dos danos que tiver experimentado, ou entregue a terceiros prejudicados, mediante ordem judicial.</p>
                  <p>15.5 A ADWEB, os USUÁRIOS ou quaisquer terceiros prejudicados poderão tomar as ações legais cabíveis em decorrência dos delitos ou contravenções de que sejam vítimas, ou ainda buscar o ressarcimento pelos prejuízos civis por descumprimento dos Termos de Uso, sem que isso acarrete qualquer direito de indenização por parte da ADWEB ao USUÁRIO acionado.</p>
                  <p>16. USO DA IMAGEM</p>
                  <p>16.1 O USUÁRIO autoriza, por meio de seu consentimento no ato da assinatura, o uso de sua imagem pessoal liberando para divulgação através de campanhas institucionais e promocionais, por meio de fotografias, vídeos e/ou áudios, em benefício da ADWEB para fins de divulgar o testemunho real do USUÁRIO sobre a ADWEB. A autorização de estende para todo e qualquer material digital, online ou impresso, seja fotos, vídeos, áudios, documentos, capturas de tela, textos e outros meios de divulgação utilizados pela ADWEB. Sendo que tais campanhas serão destinadas à divulgação pública em geral e/ou apenas para uso interno da ADWEB, desde que não haja desvirtuamento da sua finalidade.</p>
                  <p>16.2 A autorização do USUÁRIO é de expressão de sua vontade, e portanto nada há para ser reclamado a título de direitos conexos à minha imagem ou a qualquer outro, podendo a qualquer momento solicitar a ADWEB a remoção de sua imagem pessoal.</p>
                  <p>17. PROPRIEDADE INTELECTUAL</p>
                  <p>17.1 A ADWEB contém segredos comerciais, invenções patenteadas e ou patentes, bem como outros materiais de propriedade intelectual da ADWEB e seus licenciadores. Todos os direitos e propriedade do conteúdo são de posse da ADWEB Você concorda em manter essas informações que não são publicamente conhecidas confidenciais para você mesmo, não podendo transferir a terceiros sem prévio consentimento da ADWEB.</p>
                  <p>17.2 É proibida qualquer tentativa de desmontar, reconfigurar ou fazer engenharia reversa do site e aplicativo da ADWEB ou do conteúdo nele disponibilizado.</p>
                  <p>17.3 O conteúdo disponibilizado na ADWEB é protegido pelas leis e tratados de direitos autorais aplicáveis.</p>
                  <p>17.4 Você não poderá reproduzir, bem como copiar para si, sem prévia autorização da Adweb qualquer conteúdo, seja ele no todo ou em parte, exceto conforme previsto neste Termo de Uso.</p>
                  <p>17.5 Você concorda em cumprir com todas as leis de proteção dos direitos autorais em conexão com o seu uso do aplicativo Adweb e do conteúdo nele disponibilizado.</p>
                  <p>18. TERMOS E CONDIÇÕES GERAIS</p>
                  <p>18.1 Assinando um plano da ADWEB, o Usuário tem pleno conhecimento de que o sistema sofre alterações diariamente, não sendo obrigatório comunicados prévios perante cada alteração, a menos que julguemos necessário.</p>
                  <p>18.2 O Usuário também tem pleno conhecimento de que há possibilidade de se deparar com “bugs do sistema”, ou seja, falhas nos processos, bem como enfrentar lentidão e a impossibilidade de uso do sistema por algumas horas.</p>
                  <p>18.3 A plataforma ADWEB não se responsabiliza por eventuais perdas ocorridas em virtude de bugs imprevistos e/ou por conta de decorrências externas que toda empresa está sujeita.</p>
                  <p>18.4 Sempre que houver alteração destes Termos de Uso o Usuário será comunicado através de e-mail. Após 07 (sete) dias deste comunicado, se não houver manifestação, serão consideradas aceitas as alterações.</p>
                  <p>18.5 Caso o Usuário não aceite os termos de uso impostos acima, o cancelamento da assinatura pode ser solicitado através do e-mail contato@embratec.org.</p>
                  <p>18.6 Legislação em vigor. Estes Termos de uso devem ser regidos por e interpretados de acordo com a legislação do Brasil.</p>
                  <p>18.8 Atendimento ao cliente. Para obter mais informações sobre o serviço ADWEB, suas funcionalidades e recursos, ou caso precise de ajuda com sua conta, por favor, acesse o suporte através do aplicativo ou nosso site. No caso de quaisquer conflitos entre esses Termos de uso e informações fornecidas pelo Atendimento ao cliente, esses Termos de uso prevalecerão.</p>
                  <p>18.9 Validade das cláusulas. Se qualquer disposição ou disposições desses Termos de uso forem consideradas inválidas, ilegais ou não aplicáveis, a validade, legalidade e aplicabilidade das demais disposições devem permanecer em pleno vigor.</p>
                  <p>18.10 Alterações dos Termos de uso e cessão. A ADWEB poderá alterar estes Termos de uso periodicamente. Notificaremos você com pelo menos 07 (sete) dias de antecedência antes que as alterações se apliquem. </p>
                  <p>18.11 Comunicações eletrônicas. Enviaremos informações relacionadas à sua conta (por exemplo, autorizações de pagamento, cobranças, alterações de senha ou forma de pagamento, mensagens de confirmação, notificações) somente em formato eletrônico como, por exemplo, por meio de e-mails para o endereço fornecido durante o cadastro.</p>
                  <p>19. FORO</p>
                  <p>18.1 Fica eleito o foro da comarca de Montes Claros, estado de Minas Gerais para dirimir quaisquer controvérsias acerca da utilização da Ferramenta ou deste presente instrumento jurídico – Termos de Uso –  renunciando as partes quaisquer outros, por mais privilegiado que seja.</p>
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button 
                color='black' 
                onClick={() => {
                this.setState({show_modal:false, concordo_termos: false})
              }}>
                Não concordo
              </Button>
              <Button
                content="Concordo"
                labelPosition='right'
                icon='checkmark'
                positive
                onClick={() => {
                  this.setState({show_modal:false, concordo_termos: true})
                }}/>
            </Modal.Actions>
          </Modal>
        </Grid>
        
      </>
    );
  }
};

