import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Breadcrumb,
  Container,
  Menu,
  Input,
  Button,
  Icon,
  Modal,
  Grid,
  Header,
  Form,
  Select,
} from 'semantic-ui-react';
import Rotas, { breadCrumb } from '../../utils/rotas';

const GerarMidias: React.FC = () => {
  const [modalFiltroOpen, setModalFiltroOpen] = useState(false);
  const modalButton = (
    <Button inverted>
      <Icon name='list alternate' />
      Filtros
    </Button>
  );
  return (
    <Container fluid className='gerar-midias page'>
      <Menu stackable fluid borderless className='menu-advogar' attached='top'>
        <Menu.Item>
          <Breadcrumb>
            {breadCrumb[Rotas.GERAR_MIDIAS].map(bread =>
              bread.link ? (
                <>
                  <Breadcrumb.Section
                    as={NavLink}
                    to={bread.key}
                    active={bread.active}
                  >
                    {bread.content}
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon='right angle' />
                </>
              ) : (
                <Breadcrumb.Section active={bread.active}>
                  {bread.content}
                </Breadcrumb.Section>
              )
            )}
          </Breadcrumb>
        </Menu.Item>
        <Menu.Menu className='busca'>
          <Menu.Item>
            <Input icon='search' placeholder='Pesquisar...' />
          </Menu.Item>
        </Menu.Menu>
        <Menu.Menu position='right' className='filtros'>
          <Menu.Item>
            <Modal
              onClose={() => setModalFiltroOpen(false)}
              onOpen={() => setModalFiltroOpen(true)}
              open={modalFiltroOpen}
              trigger={modalButton}
              size='small'
              closeIcon
              closeOnEscape
              closeOnDimmerClick
            >
              <Modal.Header>Filtro</Modal.Header>
              <Modal.Content>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as='h2' textAlign='center'>
                        Selecione a Tag:
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered>
                    <Grid.Column>
                      <Form>
                        <Select
                          placeholder='Todas as Áreas'
                          options={[]}
                          fluid
                        />
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Modal.Content>
            </Modal>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </Container>
  );
};

export default observer(GerarMidias);
