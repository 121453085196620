import axios, { AxiosResponse } from 'axios';
import constants from '../utils/constantes';

const api_suffix = '';

axios.defaults.baseURL = constants.BASE_URL;

const responseBody = (response: AxiosResponse) => response.data;

const request = {
  get: (url: string, token: any) => axios.get(url,{headers:{Authorization: `Token ${token}`}}).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body, {headers:{Authorization: ''}}).then(responseBody),
};

const UsuarioService = {
  ativarPresentes: (token:any) =>
    request.get('api/presente/ativar-nao-utilizados/', token).catch(error => {
      throw error.response;
    }),
  getMeusPresentes: (token:any) =>
    request.get('api/presente/meus/', token).catch(error => {
      throw error.response;
    }),
  usuarioInfo: (token:any) =>
    request.get('api/usuario-info/', token).catch(error => {
      throw error.response;
    }),
  insereUsuario: (data: any) =>
    request.post('api/usuario/', data).catch(error => {
      throw error.response;
    }),
};

export default UsuarioService;
