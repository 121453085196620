import axios, { AxiosResponse } from 'axios';
import constants from '../utils/constantes';

const api_suffix = 'api/';

axios.defaults.baseURL = constants.BASE_URL;

const responseBody = (response: AxiosResponse) => response.data;

const request = {
  get: (url: string, token: any) => axios.get(url,{headers:{Authorization: `Token ${token}`}}).then(responseBody),
  put: (url: string, token: any, body: any) => axios.put(url, body,{headers:{Authorization: `Token ${token}`}}).then(responseBody),
};

const UsuarioMedusaService = {
  get: (token:any) =>
    request.get('api/usuario-medusa/', token).catch(error => {
      throw error.response;
    }),
  uploadLogo: (data:any, token:any) =>
    request.put('api/usuario-medusa/', token, data).catch(error => {
      throw error.response;
    }),

};

export default UsuarioMedusaService;
