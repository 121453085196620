import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Segment, Sidebar, Menu as MenuUI } from 'semantic-ui-react';

import './menu.scss';
import MenuIcon from '../../../assets/imgs/menu-icon.png';
import MenuFecharIcon from '../../../assets/imgs/menu-fechar-icon.png';
import Rotas from '../../../utils/rotas';
import AuthStore from '../../../stores/authStore';

const Menu: React.FC = () => {
  const [menuAberto, setMenuAberto] = useState(false);
  const authStore = useContext(AuthStore);
  const { deslogar } = authStore;
  const history = useHistory();

  const handleDeslogar = () => {
    deslogar();
    history.push(Rotas.LOGIN);
  };

  return (
    <>
      <div className='menu MenuAdvogar'>
        {menuAberto ? (
          <div className='menu__aberto'>
            <button
              type='button'
              onClick={() => setMenuAberto(false)}
              className='fechar-menu'
            >
              <img src={MenuFecharIcon} alt='Fechar Menu' />
            </button>
            <ul>
              <li key='menu-1'>
                <Link to={Rotas.DASHBOARD} className='menu-item'>
                  Dashboard
                </Link>
              </li>
              <li key='menu-2'>
                <Link to={Rotas.SITE_PROFISSIONAL} className='menu-item'>
                  Site Profissional
                </Link>
              </li>
              <li key='menu-3'>
                <Link to={Rotas.CARTAO_INTERATIVO} className='menu-item'>
                  Cartão Interativo
                </Link>
              </li>
              <li key='menu-4'>
                <Link to={Rotas.FILTRO_INSTAGRAM} className='menu-item'>
                  Filtro Instagram
                </Link>
              </li>
              <li key='menu-5'>
                <Link to={Rotas.CRIAR_MIDIAS} className='menu-item'>
                  Criar mídia
                </Link>
              </li>
              <li key='menu-6'>
                <Link to={Rotas.MEUS_MODELOS} className='menu-item'>
                  Meus modelos
                </Link>
              </li>
              <li key='menu-7'>
                <a href='javascript;' className='menu-item'>
                  Enviar sugestão de mídia
                </a>
              </li>
              <li key='menu-8'>
                <button
                  onClick={() => handleDeslogar()}
                  type='button'
                  className='menu-item'
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        ) : (
          <button
            type='button'
            onClick={() => setMenuAberto(true)}
            className='menu-icon'
          >
            <img src={MenuIcon} alt='Ícone Menu' />
          </button>
        )}
      </div>
    </>
  );
};

export default observer(Menu);
