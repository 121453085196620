import React, { useState, useEffect } from 'react';
import { NavLink, useParams, useHistory, Redirect} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Breadcrumb,
  Container,
  Menu,
  Input,
  Button,
  Icon,
  Modal,
  Grid,
  Header,
  Form,
  Select,
  List, 
  Image,
  Card 
} from 'semantic-ui-react';
import Rotas, { breadCrumb } from '../../utils/rotas';
import constants from '../../utils/constantes';
import './index.scss';
import MenuAdvogar from '../dashboard/components/menu';
import EditorAreaService from '../../services/editorAreaService';
import Loading from '../../components/Loading';

type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  redirectTo:any, 
  loading:boolean, 
  modalFiltroOpen:boolean, 
  noResults:boolean, 
  searchString: string, 
  currentPage:number, 
  modelosEditor:any[], 
  modelosEditorFiltradas:any[], 
  nextPage:string | null, 
  filterDate:string, 
};

export default class MeusModelos extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      redirectTo: false, 
      loading:true, 
      modalFiltroOpen:false, 
      noResults:false, 
      searchString: '', 
      currentPage: 1, 
      modelosEditor: [], 
      modelosEditorFiltradas: [], 
      nextPage: '',
      filterDate: ''
    };
  }

  componentDidMount = async () => {
    await this.handleGetMore(this.state.currentPage);
    this.setState({loading:false});
  }

  handleGetMore = async (page: number, date:any = '', clearValues: any = false) => {
    const modelosResponse = await EditorAreaService.getMeusModelos(page, date);
    const currentAux = this.state.modelosEditor;
    const {currentPage} = this.state;

    const newArr = [...currentAux, ...modelosResponse.results];
    if(clearValues)
    {
      const hasResults = modelosResponse.results.length===0;
      this.setState({
        currentPage: currentPage+1, 
        nextPage: modelosResponse.next, 
        modelosEditor: modelosResponse.results, 
        modelosEditorFiltradas: modelosResponse.results,
        noResults:hasResults
      });
    }
    else
    {
      const hasResults = newArr.length===0;
      this.setState({
        currentPage: currentPage+1, 
        nextPage: modelosResponse.next, 
        modelosEditor: newArr, 
        modelosEditorFiltradas: newArr,
        noResults:hasResults
      });
    }
  };

  handleFilter = async () => {
    const initialPage = 1;
    this.setState({
        currentPage: initialPage, 
        nextPage: '', 
        modelosEditor: [], 
        modelosEditorFiltradas: [],
        noResults:false
      });
    if(this.state.filterDate.length>2)
    {
      const periodoSplit = this.state.filterDate.split('-');
      let novoPeriodo = '';
      novoPeriodo = `${periodoSplit[2]}/${periodoSplit[1]}/${periodoSplit[0]}`;
      
      this.handleGetMore(initialPage,novoPeriodo, true);
    }
  };

  handleSearch = async (e:any)=>{
    let results = [];
    if(this.state.searchString.trim().toUpperCase()==='')
    {
      const {modelosEditor} = this.state;
      results = modelosEditor;
      this.setState({modelosEditorFiltradas:results});
    }
    else
    {
      for(let i=0; i<this.state.modelosEditor.length;i+= 1)
      {
        if(this.state.modelosEditor[i].titulo.toUpperCase().includes(this.state.searchString.trim().toUpperCase()))
        {
          results.push(this.state.modelosEditor[i]);
        }
      }
      this.setState({modelosEditorFiltradas:results});
    }
  };

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    const modalButton = (
      <Button inverted>
        <Icon name='list alternate' />
        Filtros
      </Button>
    );
    return (
      <Container fluid className='meus-modelos page'>
        <Menu stackable fluid borderless className='menu-advogar' attached='top'>
          <Menu.Menu className='menu-button'>
            <Menu.Item>
              <MenuAdvogar />
            </Menu.Item>
          </Menu.Menu>
          <Menu.Item className='menu-breadcrumb'>
            <Breadcrumb>
              {breadCrumb[Rotas.MEUS_MODELOS].map(bread =>
                bread.link ? (
                  <>
                    <Breadcrumb.Section
                      as={NavLink}
                      to={bread.key}
                      active={bread.active}
                    >
                      {bread.content}
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                  </>
                ) : (
                  <Breadcrumb.Section active={bread.active}>
                    {bread.content}
                  </Breadcrumb.Section>
                )
              )}
            </Breadcrumb>
          </Menu.Item>
          <Menu.Menu className='busca'>
            <Menu.Item>
              <Input action={{
                  icon: 'search',
                  onClick: this.handleSearch
                }} 
                value={this.state.searchString}
                id="searchMenu"
                onChange={(event)=>{this.setState({searchString:event.target.value})}}
                placeholder='Pesquisar...'/>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position='right' className='filtros'>
            <Menu.Item>
              <Modal
                onClose={() => this.setState({modalFiltroOpen:false})}
                onOpen={() => this.setState({modalFiltroOpen:true})}
                open={this.state.modalFiltroOpen}
                trigger={modalButton}
                size='small'
                closeIcon
                closeOnEscape
                closeOnDimmerClick
              >
                <Modal.Header>Filtro</Modal.Header>
                <Modal.Content>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as='h2' textAlign='center'>
                          Selecione a Tag:
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered>
                      <Grid.Column>
                        <Form>
                          <input type='date' value={this.state.filterDate} className='filterInput' onChange={(event) => {
                                this.setState({filterDate:event.target.value});                        
                              }}/>
                          <Button as='button' className='btnFilterModal' fluid primary onClick={() => {this.handleFilter();this.setState({modalFiltroOpen:false});}}>
                            Filtrar resultados
                          </Button>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal.Content>
              </Modal>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Container fluid className='container-body'>
          <div className="list-cards-container">
            <div className="list-cards">
              {this.state.modelosEditorFiltradas.map((modelo, i) => {
                return (
                  <a href={Rotas.MODELO.replace(':id', modelo.id)} key={modelo.id}>
                    <Card className='cardMidia'>
                      <Image src={`${constants.BASE_URL}${modelo.preview}`} wrapped ui={false} />
                      <Card.Content>
                        <Card.Header>{modelo.titulo}</Card.Header>
                      </Card.Content>
                      <Card.Content extra>
                        {modelo.descricao || 'Sem descricao'}
                      </Card.Content>
                    </Card>
                  </a>
                )})
              }
            </div>
          </div>
          {
            this.state.nextPage!=null ?
              <div className="list-cards-footer">
                <Button as='button' fluid primary onClick={() => {this.handleGetMore(this.state.currentPage)}}>
                  Carregar mais itens
                </Button>
              </div>
              :
              null
          }
          {
            this.state.noResults ?
            <div className='no-results-container'>
              <Header as='h2' icon>
                <Icon name='clone' />
                Nenhum resultado encontrado!
                <Header.Subheader>
                  Utilize o filtro para alterar os resultados!
                </Header.Subheader>
              </Header>
            </div>
            : 
            null
          }

        </Container>
      </Container>
    );
  }
};
