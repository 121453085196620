import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Rotas from './utils/rotas';
import Login from './pages/login';
import RegistrarSe from './pages/login/registrar/registrar-se';
import RegistrarEndereco from './pages/login/registrar/registrar-endereco';
import RegistrarCategoria from './pages/login/registrar/registrar-categoria';
import RegistrarPlano from './pages/login/registrar/registrar-plano';
import RegistrarCartao from './pages/login/registrar/registrar-cartao';
import RegistrarResumo from './pages/login/registrar/registrar-resumo';
import RecuperarSenha from './pages/login/recuperar-senha';
import UploadLogo from './pages/login/uploadLogo';
import Dashboard from './pages/dashboard';
import GerarMidias from './pages/gerar-midias';
import CriarMidias from './pages/criar-midias';
import Subareas from './pages/criar-midias/subareas';
import Modelo from './pages/criar-midias/modelo';
import MidiasAreas from './pages/criar-midias/midiasAreas';
import MeusModelos from './pages/criar-midias/meusModelos';
import EnviarSugestao from './pages/suporte/enviarSugestao';
import SiteProfissional from './pages/site-profissional/siteProfissional';
import SiteStory from './pages/site-story/siteStory';
import PrivateRoute from './utils/privateRoute';
import DeepLinking from './pages/deeplinking';
import FiltroInstagram from './pages/filtro-instagram';
import TermosDeUso from './pages/termos';
import RegistrarGratis from './pages/login/registrar/registrar-conta-gratis'
import RedirecionamentoStores from './pages/login/registrar/redireciona-stores-pgratis';

const App: React.FC = () => {
  return (
    <div className='App'>
      <BrowserRouter>
        <Switch>
          <Route path={Rotas.LOGIN} component={Login} exact />
          <Route path={Rotas.REGISTRAR_GRATIS} component={RegistrarGratis} exact />
          <Route path={Rotas.REGISTRAR_SE} component={RegistrarSe} exact />
          <Route path={Rotas.REGISTRAR_ENDERECO} component={RegistrarEndereco} exact />
          <Route path={Rotas.REGISTRAR_PLANO} component={RegistrarPlano} exact />
          <Route path={Rotas.REGISTRAR_CATEGORIA} component={RegistrarCategoria} exact />
          <Route path={Rotas.REGISTRAR_CARTAO} component={RegistrarCartao} exact />
          <Route path={Rotas.REGISTRAR_RESUMO} component={RegistrarResumo} exact />
          <Route path={Rotas.TERMOS} component={TermosDeUso} exact />
          <Route path={Rotas.RECUPERAR_SENHA} component={RecuperarSenha} exact />

          <Route path={Rotas.DEEPLINKING_DASHBOARD} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_SUPORTE_CRIAR_CHAMADO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_SUPORTE_ACESSAR_CHAMADO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_SUPORTE_MEUS_CHAMADOS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_SUPORTE_FAQ} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_HOME} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_MEUS_MODELOS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_NOTICIAS_HOME} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_NOTICIAS_ADVOCACIA} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_NOTICIAS_SETOR_IMOBILIARIO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_NOTICIAS_POLITICA} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_NOTICIAS_CONTABILIDADE} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_NOTICIAS_SAUDE} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_NOTICIAS_GLOBAIS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_COMEMORATIVAS_HOME} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_COMEMORATIVAS_JANEIRO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_COMEMORATIVAS_FEVEREIRO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_COMEMORATIVAS_MARCO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_COMEMORATIVAS_AGOSTO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_COMEMORATIVAS_OUTUBRO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_COMEMORATIVAS_NOVEMBRO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_COMEMORATIVAS_DEZEMBRO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_INSPIRACAO_HOME} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_INSPIRACAO_SAUDE} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_INSPIRACAO_DIREITO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_INSPIRACAO_FIM_DE_SEMANA} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_INSPIRACAO_INICIO_DE_SEMANA} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_INSPIRACAO_DO_DIA} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_INSPIRACAO_EMPREENDEDORAS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_MOMENTOS_HOME} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_MOMENTOS_DATAS_COMEMORATIVAS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_MOMENTOS_NOTICIA_DA_SEMANA} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_MOMENTOS_FRASES_INSPIRACAO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_MOMENTOS_DATA_FIM_DE_ANO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_HOME} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_ADVOCACIA} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_CONTABILIDADE} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_FISIOTERAPIA} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_NUTRICAO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_PSICOLOGIA} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_MIDIAS_GERADAS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_MIDIAS_CONTEUDO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_MIDIAS_COMEMORATIVAS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_MIDIAS_FRASES_INSPIRACAO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_MIDIAS_NOTICIAS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_SITE_PROFISSIONAL} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_SITE_STORY} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_FILTROS_INSTAGRAM} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_EMAIL_PROFISSIONAL} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_SUPORTE} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_CONTRATACAO_MIDIAS_EXTRAS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_CONTRATACAO_NOTICIAS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_CONTRATACAO_COMEMORATIVAS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_CONTRATACAO_INSPIRACAO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_CONTRATACAO_SITE_STORY} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_CONTRATACAO_SITE_PROFISSIONAL} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_CONTRATACAO_SITE_PROFISSIONAL_IOS} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_CONTRATACAO_CONTEUDO} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_CONTRATACAO_EMAIL_PROFISSIONAL} component={DeepLinking} exact />
          <Route path={Rotas.DEEPLINKING_CONTRATACAO_FILTROS_INSTAGRAM} component={DeepLinking} exact />
          
          <Route path={Rotas.REDIRECIONAR_STORES} component={RedirecionamentoStores} exact/>
         
          <PrivateRoute path={Rotas.FILTRO_INSTAGRAM} component={FiltroInstagram} />
          <PrivateRoute path={Rotas.UPLOAD_LOGO} component={UploadLogo} />
          <PrivateRoute path={Rotas.ENVIAR_SUGESTAO} component={EnviarSugestao} />
          <PrivateRoute path={Rotas.MEUS_MODELOS} component={MeusModelos} />
          <PrivateRoute path={Rotas.MODELO} component={Modelo} />
          <PrivateRoute path={Rotas.MIDIAS_AREA} component={MidiasAreas} />
          <PrivateRoute path={Rotas.SUBAREAS} component={Subareas} />
          <PrivateRoute path={Rotas.CRIAR_MIDIAS} component={CriarMidias} />
          <PrivateRoute path={Rotas.GERAR_MIDIAS} component={GerarMidias} />
          <PrivateRoute path={Rotas.SITE_PROFISSIONAL} component={SiteProfissional} />
          <PrivateRoute path={Rotas.SITE_STORY} component={SiteStory} />
          <PrivateRoute path={Rotas.CARTAO_INTERATIVO} component={SiteStory} />
          <PrivateRoute path={Rotas.DASHBOARD} component={Dashboard} />
          
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
