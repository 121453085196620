import axios, { AxiosResponse } from 'axios';
import constants from '../utils/constantes';

const token = localStorage.getItem('token');
const api_suffix = 'api/';

axios.defaults.baseURL = constants.BASE_URL;
/*
axios.defaults.headers.common.Authorization = `Token ${token}`;
*/

const responseBody = (response: AxiosResponse) => response.data;

const request = {
  get: (url: string) => axios.get(url,{headers:{Authorization: `Token ${token}`}}).then(responseBody),
};

const EditorAreaService = {
  getAreas: (_page=1) =>
    request.get(`api/editor-areas/?page=${_page}`).catch(error => {
      throw error.response;
    }),
  getMeusModelos: (_page=1, _periodo='') =>{
    if(_periodo==='' || _periodo.includes('undefined'))
    {
      return request.get(`api/editor-modelos-meus/?page=${_page}`).catch(error => {
        throw error.response;
      })
    }
    return request.get(`api/editor-modelos-meus/?page=${_page}&periodo=${_periodo}`).catch(error => {
      throw error.response;
    })
  },
  getSubAreas: (_area_pai=1,_page=1) =>
    request.get(`api/editor-subareas/${_area_pai}/?page=${_page}`).catch(error => {
      throw error.response;
    }),
  getModelosArea: (_id_area=1,_page=1, _periodo='') =>{
    if(_periodo==='' || _periodo.includes('undefined'))
    {
      return request.get(`api/editor-modelos/${_id_area}/?page=${_page}`).catch(error => {
        throw error.response;
      })
    }
    return request.get(`api/editor-modelos/${_id_area}/?page=${_page}&periodo=${_periodo}`).catch(error => {
      throw error.response;
    })
  },
  getAreaInfo: (_id_area=1) =>
    request.get(`api/editor-area-info/${_id_area}/`).catch(error => {
      throw error.response;
    }),
  getModelo: (_id_modelo=1) =>
    request.get(`api/editor-modelo/${_id_modelo}/`).catch(error => {
      throw error.response;
    }),
};

export default EditorAreaService;
