import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Segment, Sidebar, Menu, Icon } from 'semantic-ui-react';
import './index.scss';

const PaginaInterna: React.FC = ({ children }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Sidebar.Pushable as={Segment}>
      <Sidebar
        as={Menu}
        animation='overlay'
        icon='labeled'
        visible={visible}
        width='wide'
        vertical
        onHide={() => setVisible(false)}
      >
        <Menu.Item as='a'>Mídias</Menu.Item>
        <Menu.Item as='a'>Gerar Mídia</Menu.Item>
        <Menu.Item as='a'>Sugerir Notícia</Menu.Item>
        <Menu.Item as='a'>Editor</Menu.Item>
        <Menu.Item as='a'>Minha Conta</Menu.Item>
        <Menu.Item as='a'>Logout</Menu.Item>
      </Sidebar>
      <Sidebar.Pusher dimmed={visible}>
        <button
          type='button'
          onClick={() => setVisible(true)}
          className='menu-icon'
        >
          <Icon name='content' size='big' inverted />
        </button>
        <>{children}</>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default observer(PaginaInterna);
