import React, { useState, useEffect } from 'react';
import { NavLink, useParams, useHistory} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Breadcrumb,
  Container,
  Menu,
  Input,
  Button,
  Icon,
  Modal,
  Grid,
  Header,
  Form,
  Select,
  List, 
  Image,
  Card 
} from 'semantic-ui-react';
import Rotas, { breadCrumb } from '../../utils/rotas';
import constants from '../../utils/constantes';
import IModelo from '../../models/modelo';
import './enviarSugestao.scss';
import MenuAdvogar from '../dashboard/components/menu';
import EditorAreaService from '../../services/editorAreaService';

function Iframe(props:any) {
  const {iframe} = props;
  return (<div dangerouslySetInnerHTML={ {__html: iframe}} />);
}

const EnviarSugestao: React.FC = () => {
  const history = useHistory();

  const [modalFiltroOpen, setModalFiltroOpen] = useState(false);
  const modalButton = (
    <Button inverted>
      <Icon name='list alternate' />
      Filtros
    </Button>
  );
  return (
    <Container fluid className='midias-areas page'>
      <Menu stackable fluid borderless className='menu-advogar' attached='top'>
        <Menu.Menu className='menu-button'>
          <Menu.Item>
            <MenuAdvogar />
          </Menu.Item>
        </Menu.Menu>
        <Menu.Item className='menu-breadcrumb'>
          <Breadcrumb>
            {breadCrumb[Rotas.ENVIAR_SUGESTAO].map(bread =>
              bread.link ? (
                <>
                  <Breadcrumb.Section
                    as={NavLink}
                    to={bread.key}
                    active={bread.active}
                  >
                    {bread.content}
                  </Breadcrumb.Section>
                  <Breadcrumb.Divider icon='right angle' />
                </>
              ) : (
                <Breadcrumb.Section active={bread.active}>
                  {bread.content}
                </Breadcrumb.Section>
              )
            )}
          </Breadcrumb>
        </Menu.Item>
      </Menu>
      <Container fluid className='container-body'>
        <div className='iframeContainer'>
          <iframe title="iframeFormulario" id='iframeFormulario' className="iframeFull" height="100%" name='iframeFormulario' src='https://materiais.advogar.site/formulario-de-sugestao-de-noticia'/>
        </div>
      </Container>
    </Container>
  );
};

export default observer(EnviarSugestao);
