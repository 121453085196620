import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Loader,
  Segment,
  Dimmer,
  Image,
  Header,
  Button, 
  Form, 
  Icon,
} from 'semantic-ui-react';
import InputMask from 'react-input-mask';

import Rotas from '../../../utils/rotas';
import '../login.scss';
import './registrar.scss';
import Logo from '../../../assets/imgs/logo.png';
import AuthService from '../../../services/authService';
import RdStation from '../../../services/rdStation';
import IAuth from '../../../models/auth';

import FormField from '../../../components/FormField';
import Loading from '../../../components/Loading';
import constants from '../../../utils/constantes';

import CountdownTimer from '../../../components/CountdownTimer';

type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  redirectTo:any, 
  loading:boolean, 
  hasError:boolean, 
  errorMessage: string, 
  show_fields:boolean, 
  cep: string, 
  rua: string, 
  numero: string, 
  bairro: string, 
  cidade: string, 
  estado: string,

  clienteRest: any,
};

export default class RegistrarEndereco extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      redirectTo: false, 
      loading:true, 
      hasError: false, 
      errorMessage: '', 
      show_fields: false, 
      cep: '', 
      rua: '', 
      numero: '', 
      bairro: '',
      cidade: '',
      estado: '',
      clienteRest: '',
    };
  }

  componentDidMount = async () => {
    
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://www.googletagmanager.com/gtag/js?id=G-0QE4WSK3EZ';
    document.body.appendChild(s);

    const s2 = document.createElement('script');
    s2.type = 'text/javascript';
    s2.async = true;
    s2.innerHTML = 'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments)}gtag("js", new Date());gtag("config", "G-0QE4WSK3EZ");';
    document.body.appendChild(s2);

    let isLogged:any = localStorage.getItem('isLogged');
    if(isLogged!==null)
    {
      isLogged = JSON.parse(isLogged);
      if(isLogged)
      {
        this.setState({hasError:false,redirectTo:Rotas.DASHBOARD});
      }
    }
    
    let previousData = localStorage.getItem('registrarEnderecoInfo');
    if(previousData!==null && previousData!=='')
    {
      let previousDataJson = JSON.parse(previousData);
      this.setState({
        cep: previousDataJson.cep,
        rua: previousDataJson.rua,
        numero: previousDataJson.numero,
        bairro: previousDataJson.bairro,
        cidade: previousDataJson.cidade,
        estado: previousDataJson.estado,
        show_fields: true,
      });

    }

    this.setState({loading:false});
  }

  handleNextStep = async () => {
    console.log('nextStep');
    if(!this.state.show_fields)
    {
      this.searchCEP();
      return;
    }
    this.setState({hasError:false, loading: true});
    /*
    * Validate Info
    */
    if(this.state.cep==='')
    {
      this.setState({hasError:true, errorMessage:'Preencha o cep', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.rua==='')
    {
      this.setState({hasError:true, errorMessage:'Preencha a rua', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.numero==='')
    {
      this.setState({hasError:true, errorMessage:'Preencha o número', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.bairro==='')
    {
      this.setState({hasError:true, errorMessage:'Preencha o bairro', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.cidade==='')
    {
      this.setState({hasError:true, errorMessage:'Preencha a cidade', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    if(this.state.estado==='')
    {
      this.setState({hasError:true, errorMessage:'Preencha o estado', loading: false});
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });    
      return;
    }
    let clienteRest = localStorage.getItem('registrarSeInfo');
    const cliente = JSON.parse(clienteRest || '{}');

    const registerInfo = {
      cep: this.state.cep,
      rua: this.state.rua,
      numero: this.state.numero,
      bairro: this.state.bairro,
      cidade: this.state.cidade,
      estado: this.state.estado,
      email: cliente.email,
      conversion_name: 'COMPLETOU_ENDERECO',
      telefone: cliente.telefone,
      nome: cliente.nome,
    }  


    await fetch(
      `${constants.BASE_URL}api/usuario-conversion-rd/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registerInfo),
      }
    );


    localStorage.setItem('registrarEnderecoInfo', JSON.stringify(registerInfo));
    this.setState({loading: false, redirectTo:Rotas.REGISTRAR_CATEGORIA});    

  }

  searchCEP = async () =>{
    if(this.state.cep.length < 8) 
    {
      alert('CEP inválido.');
    } 
    else 
    {   
      this.setState({loading: true});
      let url = `https://viacep.com.br/ws/${this.state.cep}/json/`;
      let response = await fetch(url, {mode: 'cors'})
        .then((res) => res.json())
        .then((data) => {
          if(data.erro) 
          {
            this.setState({loading: false});
            alert('CEP inválido.');
          } 
          else 
          {
            console.log('data', data);
            console.log('rua2', data.logradouro);
            this.setState({
              rua: data.logradouro,
              bairro: data.bairro,
              cidade: data.localidade,
              estado: data.uf, 
              show_fields: true,
              loading: false
            });
          }
        })
      .catch(err => {
        this.setState({loading: false});
        alert('Ocorreu um erro ao buscar seu CEP, tente novamente mais tarde.');
        console.warn('buscacep err', err);
      });
    }  
  }

  onChangeCEP = (event:any) =>{
    this.setState({cep:event.target.value});
  }

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <>
        <Grid className='login registrar-endereco login-top-desc' verticalAlign='middle'>
          <Grid.Column tablet={12} computer={8}>
            <Segment padded>
              <Grid columns={1} className='login__title'>
                <Grid.Column>
                  <Header as='h1'>
                    Registrar <span>endereço</span>
                  </Header>
                </Grid.Column>
                <Grid.Column>
                  <CountdownTimer />
                </Grid.Column>
              </Grid>
              <Grid columns={1}>
                <Grid.Column>
                  {
                    this.state.hasError ? 
                    <div className="ErrorLogin">{this.state.errorMessage}</div>
                    : 
                    null
                  }
                  <Form>
                    <Grid columns={1}>
                      <Grid.Column>
                        <Form.Field className='form-field'>
                          <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
                          <label htmlFor='cep'>CEP</label>
                          <InputMask 
                            name='CEP'
                            mask="99999-999" 
                            id='cep'
                            placeholder='Digite o CEP do seu endereço'
                            value={this.state.cep}
                            onChange={this.onChangeCEP}
                          />
                        </Form.Field>
                        <Icon className='searchCPFIcon' name='search' onClick={() => this.searchCEP()}/>
                      </Grid.Column>
                    </Grid>

                    {
                      this.state.show_fields ?
                        <div>
                          <Grid columns={1}>
                            <Grid.Column>
                              <FormField
                                name='Rua'
                                type='text'
                                id='rua'
                                value={this.state.rua}
                                placeholder='Digite a rua do seu endereço'
                                onChange={(val:string, prevVal:string) => this.setState({rua:val})}
                              />
                            </Grid.Column>
                          </Grid>

                          <Grid columns={1}>
                            <Grid.Column>
                              <FormField
                                name='Numero'
                                type='number'
                                id='numero'
                                value={this.state.numero}
                                placeholder='Digite o número do seu endereço'
                                onChange={(val:string, prevVal:string) => this.setState({numero:val})}
                              />
                            </Grid.Column>
                          </Grid>

                          <Grid columns={1}>
                            <Grid.Column>
                              <FormField
                                name='Bairro'
                                type='text'
                                id='bairro'
                                value={this.state.bairro}
                                placeholder='Digite o bairro do seu endereço'
                                onChange={(val:string, prevVal:string) => this.setState({bairro:val})}
                              />
                            </Grid.Column>
                          </Grid>

                          <Grid columns={1}>
                            <Grid.Column>
                              <FormField
                                name='Cidade'
                                type='text'
                                id='cidade'
                                value={this.state.cidade}
                                placeholder='Digite a cidade do seu endereço'
                                onChange={(val:string, prevVal:string) => this.setState({cidade:val})}
                              />
                            </Grid.Column>
                          </Grid>

                          <Grid columns={1}>
                            <Grid.Column>
                              <FormField
                                name='Estado'
                                type='text'
                                id='estado'
                                value={this.state.estado}
                                placeholder='Digite o estado do seu endereço'
                                onChange={(val:string, prevVal:string) => this.setState({estado:val})}
                              />
                            </Grid.Column>
                          </Grid>
                          
                        </div>
                      :
                        null
                    }


                  </Form>
                  <Grid columns={1}>
                    <Grid.Column>
                      <Button
                        primary
                        size='large'
                        fluid
                        onClick={() => this.handleNextStep()}
                      >
                        Continuar
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>
        
      </>
    );
  }
};