import React, { useState, useEffect } from 'react';
import { NavLink, useParams, useHistory, Redirect} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Breadcrumb,
  Segment,
  Checkbox,
  Container,
  Menu,
  Input,
  Button,
  Icon,
  Modal,
  Grid,
  Header,
  Form,
  Select,
  List, 
  Image
} from 'semantic-ui-react';
import md5 from 'js-md5';
import ImgSiteProfissional from '../../assets/imgs/contratacao_site_profissional_icon.png';
import Rotas, { breadCrumb } from '../../utils/rotas';
import constants from '../../utils/constantes';
import IModelo from '../../models/modelo';
import IArea from '../../models/area';
import IUsuario from '../../models/usuario';
import IUsuarioMedusa from '../../models/usuarioMedusa';
import './index.scss';
import MenuAdvogar from '../dashboard/components/menu';
import EditorAreaService from '../../services/editorAreaService';
import EditorModeloService from '../../services/editorModeloService';
import UsuarioMedusaService from '../../services/usuarioMedusaService';
import ContratacaoService from '../../services/contratacaoService';
import UsuarioService from '../../services/usuarioService';
import Loading from '../../components/Loading';

type MyProps = { 
  history:any, 
  location:any, 
  match:any, 
  staticContext:any, 
};
type MyState = {  
  redirectTo:any, 
  oauth:any, 
  loading:boolean, 
  termos_uso:boolean, 
  hasAccess:boolean, 
  usuarioMedusa:IUsuarioMedusa | any, 
  usuarioInfo:IUsuario | any,
  token:string | null, 
  url:string | null, 
};
export default class SiteProfissional extends React.Component<MyProps, MyState>{
  constructor(props:any) {
    super(props);
    this.state = {
      redirectTo: false, 
      oauth: false, 
      loading:true, 
      termos_uso:false, 
      hasAccess:true, 
      usuarioMedusa: null,
      usuarioInfo: null,
      token: '',
      url: ''
    };
  }

  getOauth = async () =>{
    let requestUrl = 'https://advogar.site/oauth/token';
    let formBody = {
      grant_type: 'client_credentials',
      client_id: 2,
      client_secret: 'RM0ecTf4n44nuVlIsSDoSeLWfGx4KWdZjpDEAsGP',      
    };
    let response = await fetch(
      requestUrl,
      {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify(formBody),
        headers: {'Content-Type': 'application/json'}
      }
    ).then(res => res.json())
    .catch((error) => {
      console.log(error)
    });
    return response;
  }

  componentDidMount = async () => {
    await this.loadSiteProfissional();
  }

  componentDidUpdate = async () =>{
    if(this.state.hasAccess)
    {
      window.scrollTo(0, 150);
    }
  }

  loadSiteProfissional = async() => {
    const oauthInfo = await this.getOauth();
    this.setState({oauth: oauthInfo});

    const localToken = localStorage.getItem('token');

    const medusaInfo = await UsuarioMedusaService.get(localToken);
    this.setState({usuarioMedusa:medusaInfo});

    const usuarioAdvInfo = await UsuarioService.usuarioInfo(localToken);
    this.setState({usuarioInfo:usuarioAdvInfo});

    if(medusaInfo.tipos_areas_atuacao.includes('SITEPROFISSIONAL'))
    {
      await this.prepareUrl();
      this.setState({loading:false, hasAccess: true});
    }
    else
    {
      this.setState({loading:false, hasAccess: false});
    }
    
  };

  openInNewTab = (url:any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if(newWindow) newWindow.opener = null;
  };

  prepareUrl = async () => {
    const {usuarioInfo, usuarioMedusa, oauth} = this.state;
    let url_formatada = `https://advogar.site/api/adweb/site/login?email=${encodeURIComponent(usuarioInfo?.email)}&id=${usuarioInfo?.id}&nome=${encodeURIComponent(usuarioInfo?.nome)}&logo_original=${encodeURIComponent(usuarioMedusa?.logo_original)}&logo_branca=${encodeURIComponent(usuarioMedusa?.logo_branca)}&logo_colorida=${encodeURIComponent(usuarioMedusa?.logo_colorida)}&token=${oauth.access_token}`;
    this.openInNewTab(url_formatada)
    this.setState({url:url_formatada});
  }

  updateAccess = (condition:boolean) =>{
    this.setState({hasAccess:condition});
  };

  contratar = async () =>{
    if (this.state.termos_uso)
    {
      this.setState({loading: true});
      
      let contratacao = null
      try{
        contratacao = await ContratacaoService.SiteProfissional();
        console.log('contratacao', contratacao);
        alert(contratacao.msg);
        this.prepareUrl();        
      }
      catch (error) {
        const errors_exception = error.data;
        alert(errors_exception);
      }
    }else
    {
      alert('Para continuar com a contratação deve aceitar as informações acima.');
    }
  };

  render(){
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>;
    }
    const modalButton = (
      <Button inverted>
        <Icon name='list alternate' />
        Filtros
      </Button>
    );

    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <Container fluid className='site-profissional-page page'>
        <Menu stackable fluid borderless className='menu-advogar' attached='top'>
          <Menu.Menu className='menu-button'>
            <Menu.Item>
              <MenuAdvogar />
            </Menu.Item>
          </Menu.Menu>
          <Menu.Item className='menu-breadcrumb'>
            <Breadcrumb>
              {breadCrumb[Rotas.DASHBOARD].map(bread =>
                bread.link ? (
                  <>
                    <Breadcrumb.Section
                      as={NavLink}
                      to={bread.key}
                      active={bread.active}
                    >
                      {bread.content}
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                  </>
                ) : (
                  <Breadcrumb.Section active={bread.active}>
                    {bread.content}
                  </Breadcrumb.Section>
                )
              )}
            </Breadcrumb>
          </Menu.Item>
        </Menu>
        <Container fluid className='container-body'>
          <Grid className='site-profissional-grid' verticalAlign='middle'>
            <Grid.Column tablet={12} computer={8}>
              {
                this.state.hasAccess ?
                  <Segment padded>
                    <Grid columns={1} className='site-profissional-titulo'>
                      <Grid.Column>
                        <Header as='h1'>
                          Atenção! Caso a janela não tenha sido aberta, verifique se liberou os popups do seu navegador e atualize a página.
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Grid centered columns={1} padded='vertically'>
                      <Grid.Column>
                        <Image src={ImgSiteProfissional} centered />
                      </Grid.Column>
                    </Grid>

                  </Segment>

                :
                  <Segment padded>
                    <Grid columns={1} className='site-profissional-titulo'>
                      <Grid.Column>
                        <Header as='h1'>
                          Tenha um Site Profissional <span>em minutos</span>
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Grid centered columns={1} padded='vertically'>
                      <Grid.Column>
                        <Image src={ImgSiteProfissional} centered />
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1} className='site-profissional-subtitulo'>
                      <Grid.Column>
                        <Header as='h3'>
                          <span>Domínio Próprio, templates personalizáveis, hospedagem e editor fácil.</span>
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1} className='site-profissional-destaque'>
                      <Grid.Column>
                        <Header as='h3'>
                          EXPERIMENTE GRÁTIS POR 30 DIAS
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1} className='site-profissional-preco'>
                      <Grid.Column>
                        <Header as='h4'>
                          R$ 19,99/Mês
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1}>
                      <Grid.Column>
                        <Checkbox 
                          label='Declaro que li e concordo com as informações acima.' 
                          id='termos_uso'
                          defaultChecked={this.state.termos_uso}
                          checked={this.state.termos_uso}
                            onChange={(e:any,data:any) => {
                              this.setState({termos_uso:data.checked})
                            }}
                        />
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1}>
                      <Grid.Column>
                        <Button
                          primary
                          size='medium'
                          fluid
                          onClick={() => this.openInNewTab('https://modelo3.advogar.site/')}
                        >
                          Modelo Advogado
                        </Button>
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1}>
                      <Grid.Column>
                        <Button
                          primary
                          size='medium'
                          fluid
                          onClick={() => this.openInNewTab('https://modelo8.advogar.site/')}
                        >
                          Modelo Saúde
                        </Button>
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1}>
                      <Grid.Column>
                        <Button
                          primary
                          size='medium'
                          fluid
                          onClick={() => this.openInNewTab('https://modelo10.advogar.site/')}
                        >
                          Modelo Contador
                        </Button>
                      </Grid.Column>
                    </Grid>
                    <Grid columns={1}>
                      <Grid.Column>
                        <Button
                          primary
                          className='btnSuccess'
                          size='large'
                          fluid
                          onClick={() => this.contratar()}
                        >
                          Contratar
                        </Button>
                      </Grid.Column>
                    </Grid>

                  </Segment>
              }
            </Grid.Column>
          </Grid>
        </Container>
      </Container>
    );
  }
};

