import { createContext } from 'react';
import {
  observable,
  action,
  runInAction,
  toJS,
  makeObservable,
  computed,
} from 'mobx';
import AuthService from '../services/auth';
import IAuth from '../models/auth';
import IAuthResponse from '../models/authResponse';

class AuthStore {
  @observable authRegistry = new Map();

  @observable auth: IAuth | null = null;

  @observable submitting = false;

  @observable fullResponse: any = null;

  @observable errorMessage = '';

  constructor() {
    makeObservable(this);
  }

  @action autenticar = async (auth: IAuth) => {
    this.submitting = true;

    try {
      const authResponse: any = await AuthService.Login.auth(auth);
      this.fullResponse = authResponse;
      runInAction(() => {
        this.authRegistry.set('token', authResponse.token);
        localStorage.setItem('token', authResponse.token);
        localStorage.setItem('isLogged', JSON.stringify(true));
        localStorage.setItem('loginError', '');
        this.submitting = false;
        return toJS(authResponse);
      });
    } catch (error) {
      runInAction(() => {
        localStorage.setItem('isLogged', JSON.stringify(false));
        this.authRegistry.clear();
        localStorage.setItem('token', '');
        localStorage.removeItem('token');
        const { status, data } = error;
        if (status === 400) {
          Object.keys(data).forEach(key => {
            this.errorMessage = data[key];
          });
        } else {
          this.errorMessage =
            'Ocorreu um erro na sua solicitação. Por favor, tente novamente.';
        }
        this.submitting = false;
        localStorage.setItem('loginError', JSON.stringify(this.errorMessage));
      });
    }
  };

  @action deslogar = async () => {
    localStorage.setItem('isLogged', JSON.stringify(false));
    localStorage.setItem('loginError', '');
    this.submitting = true;
    this.authRegistry.clear();
    this.submitting = false;
  };

  @computed get lastResponse() {
    return this.fullResponse;
  }

  @computed get isLogged() {
    const token = localStorage.getItem('token') || '';
    if (token !== '') {
      this.authRegistry.set('token', token);
      return true;
    }
    return false;
  }
}

export default createContext(new AuthStore());
