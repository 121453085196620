const Rotas = {
  LOGIN: '/login',
  TERMOS: '/termos',
  REDIRECIONAR_STORES: '/redirecionar/lojas',
  REGISTRAR_GRATIS: '/registrar/gratis',
  REGISTRAR_SE: '/registrar/informacoes',
  REGISTRAR_ENDERECO: '/registrar/endereco',
  REGISTRAR_PLANO: '/registrar/plano',
  REGISTRAR_CATEGORIA: '/registrar/categoria',
  REGISTRAR_CARTAO: '/registrar/cartao',
  REGISTRAR_RESUMO: '/registrar/resumo',
  UPLOAD_LOGO: '/upload-logo',
  ENVIAR_SUGESTAO: '/enviar-sugestao',
  CRIAR_MIDIAS: '/criar-midias',
  MEUS_MODELOS: '/meus-modelos',
  SUBAREAS: '/subareas/:id',
  MIDIAS_AREA: '/midias-area/:id',
  MODELO: '/modelo/:id',
  MIDIAS: '/midias',
  GERAR_MIDIAS: '/gerar-midias',
  RECUPERAR_SENHA: '/recuperar-senha',
  SITE_PROFISSIONAL: '/servicos/site-profissional',
  SITE_STORY: '/servicos/site-story',
  CARTAO_INTERATIVO: '/servicos/cartao-interativo',
  FILTRO_INSTAGRAM: '/servicos/filtro-instagram',
  DASHBOARD: '/',
  DEEPLINKING_DASHBOARD: '/dashboard',
  DEEPLINKING_SUPORTE_CRIAR_CHAMADO: '/suporte/criar-chamado',
  DEEPLINKING_SUPORTE_ACESSAR_CHAMADO: '/suporte/acessar-chamado',
  DEEPLINKING_SUPORTE_MEUS_CHAMADOS: '/suporte/acessar-meus-chamado',
  DEEPLINKING_SUPORTE_FAQ: '/suporte/faq',
  DEEPLINKING_EDITOR_HOME: '/editor/home',
  DEEPLINKING_EDITOR_MEUS_MODELOS:'/editor/meus-modelos',
  DEEPLINKING_EDITOR_NOTICIAS_HOME:'/editor/noticias/home',
  DEEPLINKING_EDITOR_NOTICIAS_ADVOCACIA:'/editor/noticias/advocacia',
  DEEPLINKING_EDITOR_NOTICIAS_SETOR_IMOBILIARIO:'/editor/noticias/setor-imobiliario',
  DEEPLINKING_EDITOR_NOTICIAS_POLITICA:'/editor/noticias/politica',
  DEEPLINKING_EDITOR_NOTICIAS_CONTABILIDADE:'/editor/noticias/contabilidade',
  DEEPLINKING_EDITOR_NOTICIAS_SAUDE:'/editor/noticias/saude',
  DEEPLINKING_EDITOR_NOTICIAS_GLOBAIS:'/editor/noticias/globais',
  DEEPLINKING_EDITOR_COMEMORATIVAS_HOME:'/editor/comemorativas/home',
  DEEPLINKING_EDITOR_COMEMORATIVAS_JANEIRO:'/editor/comemorativas/janeiro',
  DEEPLINKING_EDITOR_COMEMORATIVAS_FEVEREIRO:'/editor/comemorativas/fevereiro',
  DEEPLINKING_EDITOR_COMEMORATIVAS_MARCO:'/editor/comemorativas/marco',
  DEEPLINKING_EDITOR_COMEMORATIVAS_AGOSTO:'/editor/comemorativas/agosto',
  DEEPLINKING_EDITOR_COMEMORATIVAS_OUTUBRO:'/editor/comemorativas/outubro',
  DEEPLINKING_EDITOR_COMEMORATIVAS_NOVEMBRO:'/editor/comemorativas/novembro',
  DEEPLINKING_EDITOR_COMEMORATIVAS_DEZEMBRO:'/editor/comemorativas/dezembro',
  DEEPLINKING_EDITOR_INSPIRACAO_HOME:'/editor/inspiracao/home',
  DEEPLINKING_EDITOR_INSPIRACAO_SAUDE:'/editor/inspiracao/saude',
  DEEPLINKING_EDITOR_INSPIRACAO_DIREITO:'/editor/inspiracao/direito',
  DEEPLINKING_EDITOR_INSPIRACAO_FIM_DE_SEMANA:'/editor/inspiracao/fim-de-semana',
  DEEPLINKING_EDITOR_INSPIRACAO_INICIO_DE_SEMANA:'/editor/inspiracao/inicio-de-semana',
  DEEPLINKING_EDITOR_INSPIRACAO_DO_DIA:'/editor/inspiracao/inspiracao-do-dia',
  DEEPLINKING_EDITOR_INSPIRACAO_EMPREENDEDORAS:'/editor/inspiracao/empreendedoras',
  DEEPLINKING_EDITOR_MOMENTOS_HOME:'/editor/momentos/home',
  DEEPLINKING_EDITOR_MOMENTOS_DATAS_COMEMORATIVAS:'/editor/momentos/datas-comemorativas-gratuitas',
  DEEPLINKING_EDITOR_MOMENTOS_NOTICIA_DA_SEMANA:'/editor/momentos/noticia-da-semana-gratuita',
  DEEPLINKING_EDITOR_MOMENTOS_FRASES_INSPIRACAO:'/editor/momentos/frases-de-inspiracao-gratuitas',
  DEEPLINKING_EDITOR_MOMENTOS_DATA_FIM_DE_ANO:'/editor/momentos/data-fim-de-ano',
  DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_HOME:'/editor/templates-de-conteudo/home',
  DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_ADVOCACIA:'/editor/templates-de-conteudo/advocacia',
  DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_CONTABILIDADE:'/editor/templates-de-conteudo/contabilidade',
  DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_FISIOTERAPIA:'/editor/templates-de-conteudo/fisioterapia',
  DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_NUTRICAO:'/editor/templates-de-conteudo/nutricao',
  DEEPLINKING_EDITOR_TEMPLATES_CONTEUDO_PSICOLOGIA:'/editor/templates-de-conteudo/psicologia',
  DEEPLINKING_MIDIAS_GERADAS:'/midias/geradas',
  DEEPLINKING_MIDIAS_CONTEUDO:'/midias/conteudo',
  DEEPLINKING_MIDIAS_COMEMORATIVAS:'/midias/comemorativas',
  DEEPLINKING_MIDIAS_FRASES_INSPIRACAO:'/midias/frases-inspiracao',
  DEEPLINKING_MIDIAS_NOTICIAS:'/midias/noticias',
  DEEPLINKING_SITE_PROFISSIONAL:'/site-profissional',
  DEEPLINKING_SITE_STORY:'/site-story',
  DEEPLINKING_FILTROS_INSTAGRAM:'/filtros-instagram',
  DEEPLINKING_EMAIL_PROFISSIONAL:'/email-profissional',
  DEEPLINKING_SUPORTE:'/suporte',
  DEEPLINKING_CONTRATACAO_MIDIAS_EXTRAS:'/contratacao/midias-extras',
  DEEPLINKING_CONTRATACAO_NOTICIAS:'/contratacao/noticias',
  DEEPLINKING_CONTRATACAO_COMEMORATIVAS:'/contratacao/comemorativas',
  DEEPLINKING_CONTRATACAO_INSPIRACAO:'/contratacao/inspiracao',
  DEEPLINKING_CONTRATACAO_SITE_STORY:'/contratacao/site-story',
  DEEPLINKING_CONTRATACAO_SITE_PROFISSIONAL:'/contratacao/site-profissional',
  DEEPLINKING_CONTRATACAO_SITE_PROFISSIONAL_IOS:'/contratacao/site-profissional-ios',
  DEEPLINKING_CONTRATACAO_CONTEUDO:'/contratacao/conteudo',
  DEEPLINKING_CONTRATACAO_EMAIL_PROFISSIONAL:'/contratacao/email-profissional',
  DEEPLINKING_CONTRATACAO_FILTROS_INSTAGRAM:'/contratacao/filtros-instagram',
};

export const breadCrumb = {
  [Rotas.ENVIAR_SUGESTAO]: [
    { key: Rotas.DASHBOARD, content: 'Dashboard', link: true },
    { key: Rotas.ENVIAR_SUGESTAO, content: 'Enviar sugestão', active: true }
  ],
  [Rotas.DASHBOARD]: [
    { key: Rotas.DASHBOARD, content: 'Dashboard', link: true },
  ],
  [Rotas.MEUS_MODELOS]: [
    { key: Rotas.DASHBOARD, content: 'Dashboard', link: true },
    { key: Rotas.MEUS_MODELOS, content: 'Meus Modelos', active: true }
  ],
  [Rotas.CRIAR_MIDIAS]: [
    { key: Rotas.DASHBOARD, content: 'Dashboard', link: true },
    { key: Rotas.CRIAR_MIDIAS, content: 'Criar Mídias', active: true },
  ],
  [Rotas.GERAR_MIDIAS]: [
    { key: Rotas.DASHBOARD, content: 'Dashboard', link: true },
    { key: Rotas.GERAR_MIDIAS, content: 'Gerar Mídias', active: true },
  ],
};

export default Rotas;
