import React from 'react';
import { Container, Grid, Image, Header } from 'semantic-ui-react';

import constantes from '../../../utils/constantes';
import DashboardLogo from '../../../assets/imgs/dashboard-logo.png';
import IUsuario from '../../../models/usuario';
import IUsuarioMedusa from '../../../models/usuarioMedusa';
import MenuAdvogar from './menu';

interface IDashboardHeader {
  usuario: IUsuario | null;
  usuarioMedusa: IUsuarioMedusa | null;
}

const DashboardHeader: React.FC<IDashboardHeader> = ({
  usuario,
  usuarioMedusa,
}) => {
  const logo =
    usuarioMedusa && (usuarioMedusa.logo_colorida || !usuarioMedusa.logo_branca)
      ? constantes.IMGURL + usuarioMedusa.logo_colorida
      : usuarioMedusa &&
        (!usuarioMedusa.logo_colorida || usuarioMedusa.logo_branca)
      ? constantes.IMGURL + usuarioMedusa.logo_branca
      : DashboardLogo;

  const logoClassName =
    usuarioMedusa && (usuarioMedusa.logo_colorida || !usuarioMedusa.logo_branca)
      ? 'mainLogo colorida'
      : usuarioMedusa &&
        (!usuarioMedusa.logo_colorida || usuarioMedusa.logo_branca)
      ? 'mainLogo branca'
      : 'mainLogo padrao';

  const logoStatus = usuarioMedusa && usuarioMedusa.status_logo!== 'LIBERADA'
      ? 'info__username failed'
      : 'info__username';

  const postFixLogoClass = usuarioMedusa && usuarioMedusa.status_logo ? usuarioMedusa.status_logo : 'NAO_ENVIADO';
  const logoContainerClass = `LogoContainer ${postFixLogoClass}`

  return (
    <Container fluid className='dashboard-header'>
      <MenuAdvogar />
      <Container className='logoStatusInfo' fluid>
        <Grid columns={1}>
          <Grid.Column>
            <Header as='h2' textAlign='center'>
              Status da Logo:
            </Header>
          </Grid.Column>
        </Grid>
        <Grid columns={3} className='logoStatusInfoGroup'>
          <Grid.Column>
            <Header as='h4' textAlign='center' className='logoStatusNaoEnviada'>
              Logo não enviada
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h4' textAlign='center' className='logoStatusEmTratamento'>
              Logo em tratamento
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as='h4' textAlign='center' className='logoStatusLiberada'>
              Logo liberada
            </Header>
          </Grid.Column>
        </Grid>
      </Container>
      <Grid columns={1}>
        <Grid.Column>
          <div className={logoContainerClass}>
            <div className='logoContainerInner'>
              <Image src={logo} centered size='medium' className={logoClassName} />
            </div>
          </div>
        </Grid.Column>
      </Grid>
      <Container className='info' fluid>
        <Grid columns={1}>
          <Grid.Column>
            <Header as='h2' className={logoStatus} textAlign='center'>
              {usuario?.nome} {usuario?.sobrenome}
            </Header>
            <hr className='subtitleDivider'/>
            <Header as='h3' className='info__username none' textAlign='center'>
              {usuario?.email}
            </Header>
          </Grid.Column>
        </Grid>
      </Container>
    </Container>
  );
};

export default DashboardHeader;
