import axios, { AxiosResponse } from 'axios';
import constants from '../utils/constantes';
import IAuth from '../models/auth';

axios.defaults.baseURL = constants.BASE_URL;
const api_suffix = 'api/';

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  post: (url: string, body: {}) => axios.post(url, body, {headers:{Authorization: ''}}).then(responseBody),
};
const Login = {
  auth: (auth: IAuth) =>
    requests.post('api/autenticacao/', auth)
    .catch(error => {
      throw error.response;
    }),
};

export default { Login };
