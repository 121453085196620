import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Loader,
  Segment,
  Dimmer,
  Image,
  Header,
  Button, 
  Form, 
} from 'semantic-ui-react';

import Rotas from '../../../utils/rotas';
import '../login.scss';
import Logo from '../../../assets/imgs/logo.png';
import FormField from '../../../components/FormField';

const RecuperarSenha: React.FC = () => {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState('');

  const handleOnClick = () => {
    console.log('handleOnClick', true);
  };

  if (submitting) {
    return (
      <Segment>
        <Dimmer active inverted page>
          <Loader size='big' />
        </Dimmer>
      </Segment>
    );
  }

  return (
    <>
      <Grid className='login' verticalAlign='middle'>
        <Grid.Column tablet={12} computer={8}>
          <Segment padded>
            <Grid centered columns={1} padded='vertically'>
              <Grid.Column>
                <Image src={Logo} centered />
              </Grid.Column>
            </Grid>
            <Grid columns={1} className='login__title'>
              <Grid.Column>
                <Header as='h1'>
                  Recuperar senha da <span>sua conta</span>
                </Header>
              </Grid.Column>
            </Grid>
            <Grid columns={1}>
              <Grid.Column>
                <Form>
                  <Grid columns={1}>
                    <Grid.Column>
                      <FormField
                        name='E-mail'
                        type='email'
                        id='email'
                        placeholder='Digite o endereço de e-mail'
                        onChange={setEmail}
                      />
                    </Grid.Column>
                  </Grid>
                </Form>
                <Grid columns={1}>
                  <Grid.Column>
                    <Button
                      primary
                      size='large'
                      fluid
                      onClick={() => handleOnClick()}
                      loading={submitting}
                    >
                      Recuperar
                    </Button>
                  </Grid.Column>
                  <Grid columns={1} className='actions'>
                    <Grid.Column>
                      <p>
                        <Link className='btnVoltar' to={Rotas.LOGIN}>Voltar</Link>
                      </p>
                      <p>
                        <a href='https://materiais.advogar.site/adweb' target='_blank' rel="noreferrer">
                          Não tem uma conta no Adweb? <span>Registre-se</span>
                        </a>
                      </p>
                    </Grid.Column>
                  </Grid>
                </Grid>
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default observer(RecuperarSenha);
