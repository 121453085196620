import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthStore from '../stores/authStore';
import Rotas from './rotas';
import PaginaInterna from '../components/PaginaInterna';

interface IPrivateRoute {
  path: string;
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({
  component: Component,
  ...rest
}) => {
  const authStore = useContext(AuthStore);
  const { isLogged } = authStore;

  return (
    <Route
      {...rest}
      exact
      render={props =>
        isLogged ? (
          <PaginaInterna>
            <Component {...props} />
          </PaginaInterna>
        ) : (
          <Redirect to={Rotas.LOGIN} />
        )
      }
    />
  );
};

export default observer(PrivateRoute);
